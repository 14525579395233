export const Interaction: React.FC<{
  children: React.ReactElement | React.ReactElement[];
}> = props => {
  return (
    <div
      style={{
        borderLeft: '1em solid yellow',
        paddingLeft: '0.5em',
        marginTop: '1em',
        marginBottom: '1em',
      }}
    >
      {props.children}
    </div>
  );
};
