import { useStore } from '../../store';
import { FeatureOptionPreferenceRow } from './feature-option-preference-row';

export const FeaturePreferenceSubTable: React.FC<{
  featureId: string;
}> = props => {
  const featureOptions = useStore(s => s.featureOptions(props.featureId));

  return (
    <>
      <tr>
        <th colSpan={3} style={{ textIndent: '1em', paddingTop: '0.5em' }}>
          {props.featureId.toUpperCase().padEnd(11, '.')} (distribution of
          customers)
        </th>
      </tr>
      {featureOptions.map((option, i) => (
        <FeatureOptionPreferenceRow
          key={[option.featureId, option.optionId].join('')}
          featureId={option.featureId}
          optionId={option.optionId}
        />
      ))}
    </>
  );
};
