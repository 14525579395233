export const featureOptions = [
  {
    featureId: 'design',
    optionId: 'cheap',
    isAvailable: true,
    extraCostPerUnit: 0,
    extraFixedCost: 0,
  },
  {
    featureId: 'design',
    optionId: 'average',
    isAvailable: false,
    extraCostPerUnit: 500,
    extraFixedCost: 25000,
  },
  {
    featureId: 'design',
    optionId: 'great',
    isAvailable: false,
    extraCostPerUnit: 2000,
    extraFixedCost: 50000,
  },
  {
    featureId: 'design',
    optionId: 'super',
    isAvailable: false,
    extraCostPerUnit: 5000,
    extraFixedCost: 75000,
  },
  {
    featureId: 'size',
    optionId: 'compact',
    isAvailable: true,
    extraCostPerUnit: 0,
    extraFixedCost: 0,
  },
  {
    featureId: 'size',
    optionId: 'sedan',
    isAvailable: true,
    extraCostPerUnit: 1500,
    extraFixedCost: 25000,
  },
  {
    featureId: 'size',
    optionId: 'suv',
    isAvailable: false,
    extraCostPerUnit: 4000,
    extraFixedCost: 50000,
  },
  {
    featureId: 'size',
    optionId: 'pick-up',
    isAvailable: false,
    extraCostPerUnit: 9000,
    extraFixedCost: 75000,
  },
  {
    featureId: 'performance',
    optionId: 'slow',
    isAvailable: true,
    extraCostPerUnit: 0,
    extraFixedCost: 0,
  },
  {
    featureId: 'performance',
    optionId: 'average',
    isAvailable: false,
    extraCostPerUnit: 1500,
    extraFixedCost: 5000,
  },
  {
    featureId: 'performance',
    optionId: 'good',
    isAvailable: false,
    extraCostPerUnit: 2500,
    extraFixedCost: 10000,
  },
  {
    featureId: 'performance',
    optionId: 'sport',
    isAvailable: false,
    extraCostPerUnit: 3500,
    extraFixedCost: 15000,
  },
  {
    featureId: 'range',
    optionId: 'city',
    isAvailable: true,
    extraCostPerUnit: 0,
    extraFixedCost: 0,
  },
  {
    featureId: 'range',
    optionId: 'good',
    isAvailable: false,
    extraCostPerUnit: 2500,
    extraFixedCost: 20000,
  },
  {
    featureId: 'range',
    optionId: 'long',
    isAvailable: false,
    extraCostPerUnit: 5000,
    extraFixedCost: 35000,
  },
  {
    featureId: 'range',
    optionId: 'top',
    isAvailable: false,
    extraCostPerUnit: 8000,
    extraFixedCost: 65000,
  },
  {
    featureId: 'safety',
    optionId: 'adequate',
    isAvailable: true,
    extraCostPerUnit: 0,
    extraFixedCost: 0,
  },
  {
    featureId: 'safety',
    optionId: 'average',
    isAvailable: false,
    extraCostPerUnit: 1500,
    extraFixedCost: 5000,
  },
  {
    featureId: 'safety',
    optionId: 'good',
    isAvailable: false,
    extraCostPerUnit: 3000,
    extraFixedCost: 10000,
  },
  {
    featureId: 'safety',
    optionId: 'outstanding',
    isAvailable: false,
    extraCostPerUnit: 7500,
    extraFixedCost: 15000,
  },
];
