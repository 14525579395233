import { useStore } from '../../store';

export const GameStatus = () => {
  const isStarted = useStore(s => s.isStarted);
  const isStopped = useStore(s => s.isStopped);

  if (!isStarted) return <span>The game is not started yet.</span>;
  if (isStarted && !isStopped) return <span>The game is in progress.</span>;
  if (isStopped)
    return (
      <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>
        The game is over.
      </span>
    );
};
