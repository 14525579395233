export const IndexOfReports = () => (
  <>
    <h5>Index of Reports</h5>
    <ul>
      <li>
        <a href="#cost-per-unit-breakdown">Cost per Unit Breakdown</a>
      </li>
      <li>
        <a href="#economy-of-learning">Economy of Learning</a>
      </li>
      <li>
        <a href="#production-capacity">Production Capacity Summary</a>
      </li>
      <li>
        <a href="#competitors-tracking">Competitors Tracking</a>
      </li>
      <li>
        <a href="#demand-and-preference">Demand and Preference Report</a>
      </li>
      <li>
        <a href="#production-volume-summary">Production Volume Summary</a>
      </li>
      <li>
        <a href="#income-statement">Income Statement</a>
      </li>
    </ul>
  </>
);
