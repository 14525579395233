import numeral from 'numeral';
import { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store';

export const ProductPriceInput = () => {
  const ref = useRef<HTMLInputElement>();

  const price = useStore(s => s.price);
  const assignPrice = useStore(s => s.assignPrice);
  const [tempPrice, setTempPrice] = useState(price);
  const [cursor, setCursor] = useState(0);
  const [diff, setDiff] = useState(0);

  // console.log(numeral(diff).format('$ 0,0.00'), cursor);

  useEffect(() => {
    ref.current.setSelectionRange(Math.max(cursor, 3), Math.max(cursor, 3));
  }, [cursor]);

  useEffect(() => {
    setTempPrice(price);
  }, [price]);

  return (
    <>
      <input
        ref={ref}
        type="text"
        value={numeral(tempPrice).format('$ 0,0.00')}
        onChange={e => {
          console.log();
          setDiff(numeral(e.target.value).value() - tempPrice);
          setCursor(e.target.selectionStart);
          setTempPrice(numeral(e.target.value).value());
        }}
        onBlur={e => {
          assignPrice(numeral(e.target.value).value());
        }}
        style={{ width: '100%' }}
      />
    </>
  );
};
