import React from 'react';
import { Interaction } from '../layout/interaction';
import { SectionTitle } from '../layout/section-title';
import { TaskListByCategory } from '../tasks/task-list-by-category';

export const BoostBrandAwareness = () => (
  <>
    <SectionTitle id="boost-brand-awareness">
      Tasks to Boost Brand Awareness
    </SectionTitle>
    <p>
      Make yourself known. Spread the word, grow your audience, and establish
      high brand recognition within your target market. Make your presence known
      on the market to enjoy an increased customer trust, increased credibility,
      increased customer loyalty, and lower price sensitivity.
    </p>

    <Interaction>
      <TaskListByCategory categoryId="improve-brand-awareness" />
    </Interaction>
  </>
);
