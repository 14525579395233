import { Duration } from 'luxon';
import { updateEvery } from '../utils/update-every';
import { useStore } from '../../store';

export const TimeElapsed = updateEvery(1000)(() => {
  const timeElapsed = useStore(store => store.timeElapsed);

  return (
    <p>
      Time elapsed:{' '}
      {Duration.fromMillis(timeElapsed(Date.now())).toFormat('m:s')}
    </p>
  );
});
