import { useStore } from '../../store';

export const PriceUnderCostMessage = () => {
  const isPriceUnderCost = useStore(s => s.isPriceUnderCost());

  return (
    <span
      style={{
        color: 'red',
        visibility: isPriceUnderCost ? 'visible' : 'hidden',
      }}
    >
      You are selling under cost here, you know?
    </span>
  );
};
