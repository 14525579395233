import React from 'react';
import { Instructions } from '../layout/instructions';
import { Interaction } from '../layout/interaction';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { CapacityReport } from '../product/capacity-report';
import { FeatureSelectionTable } from '../product/feature-selection-table';
import { LearningReport } from '../product/report.learning';
import { VariableCostReport } from '../product/report.variable-cost';
import { Term } from '../term';

export const DefineYourProduct = () => (
  <>
    <SectionTitle id="define-your-product">Define your Product</SectionTitle>

    <p>
      This is the section in which you can configure your car. Your car is made
      up of <Term lemma="5 Features" />, each one of them as a series of Options
      you can choose from. Different options have, of course, different costs.
      Not all <Term lemma="Options" /> are available right from the start. To
      get more Options you might want to run Tasks that will help you to invest
      in research and/or acquire more Know-How.
    </p>

    <p>
      Mind, all 5 features must be configured before you’re able to launch your
      car on the market. <Term lemma="Primary Features" /> can be changed only
      up to the first launch. <Term lemma="Secondary Features" /> can be changed
      at any point in time in the game (provided you still have enough time and
      money). There are 3 reports available here to help you keep track of your
      costs per unit, economy of learning, and production capacity.
    </p>

    <h3>Select your Product's Features</h3>

    <h4>Primary Features</h4>
    <Interaction>
      <FeatureSelectionTable featureIds={['design', 'size']} />
    </Interaction>

    <h4>Secondary Features</h4>
    <Interaction>
      <FeatureSelectionTable featureIds={['performance', 'range', 'safety']} />
    </Interaction>

    <h3 id="cost-per-unit-breakdown">Cost per Unit Breakdown</h3>
    <p>
      This report helps you keep track of your costs. The features you’ve
      selected have an impact, but some Tasks can help you become more efficient
      and reduce some of the costs.
    </p>
    <Report>
      <VariableCostReport />
    </Report>
  </>
);
