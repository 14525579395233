import { useState } from 'react';
import useInterval from 'use-interval';

export const updateEvery = (millis: number) => {
  function Wrapper<T>(Component: React.FC<T>) {
    return (props: T) => {
      const [count, setCount] = useState(0);

      useInterval(() => {
        setCount(count + 1);
      }, 1000);

      return <Component {...props} />;
    };
  }

  return Wrapper;
};
