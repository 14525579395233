import { FeatureOptionsSelect } from './feature-options-select';
import { FeatureExtraCost } from './feature-extra-cost';
import { FeatureExtraFixedCost } from './feature-extra-fixed-cost';
import { FeatureLevel } from './feature-level';

export const FeatureSelectionTable: React.FC<{
  featureIds: string[];
}> = props => (
  <table style={{ tableLayout: 'fixed', width: '100%' }}>
    <thead>
      <tr
        style={{
          verticalAlign: 'top',
          lineHeight: '1em',
        }}
      >
        <th style={{ width: '15%' }}>Feature</th>
        <th style={{ width: '10%', textAlign: 'center' }}>Lev.</th>
        <th style={{ width: '45%' }}>Option</th>
        <th style={{ width: '15%' }}>Extra cost per unit</th>
        <th style={{ width: '15%' }}>Extra fixed cost</th>
      </tr>
    </thead>
    <tbody>
      {props.featureIds.map(featureId => (
        <tr key={featureId}>
          <td>{featureId}</td>
          <td style={{ textAlign: 'center' }}>
            <FeatureLevel featureId={featureId} />
          </td>
          <td>
            <FeatureOptionsSelect featureId={featureId} />
          </td>
          <td>
            <FeatureExtraCost featureId={featureId} />
          </td>
          <td>
            <FeatureExtraFixedCost featureId={featureId} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
