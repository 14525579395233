import React from 'react';
import { useStore } from '../../store';
import { FeatureIds } from '../../store/concepts/product';
import { units, param, percent, priceInK } from '../layout/formatters';

export const ChallengeResultTable = () => {
  const year = useStore(s => s.currentSprint);
  const segmentPotentialDemand = useStore(s => s.segmentPotentialDemand);

  return (
    <>
      <h5>Mass Market</h5>
      <YearByCompetitorTable
        yearRange={[1, year + 2]}
        potentialDemand={year => segmentPotentialDemand('mass', year)}
      >
        {props => <MarketSegmentDetail segmentId={'mass'} {...props} />}
      </YearByCompetitorTable>

      <h5>Luxury Market</h5>
      <YearByCompetitorTable
        yearRange={[1, year + 2]}
        potentialDemand={year => segmentPotentialDemand('luxury', year)}
      >
        {props => <MarketSegmentDetail segmentId={'luxury'} {...props} />}
      </YearByCompetitorTable>
    </>
  );
};

const CompetitorByYearTable: React.FC<{
  children: React.FC<{ competitor: string; year: number }>;
}> = props => {
  const challenges = useStore(s => s.challenges());
  const competitors = useStore(s => s.competitors());
  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead>
        <tr
          style={{
            verticalAlign: 'top',
            lineHeight: '1em',
          }}
        >
          <>
            <th style={{ width: '5%' }}>#</th>
            {challenges.map(challenge => (
              <th style={{ textAlign: 'right' }} key={challenge}>
                {challenge}
              </th>
            ))}
          </>
        </tr>
      </thead>
      <tbody>
        {competitors.map((competitor, i) => (
          <tr key={competitor} style={{ background: i % 2 ? '#eee' : 'white' }}>
            <>
              <td>{competitor}</td>
              {challenges.map(challenge => (
                <td
                  key={challenge}
                  style={{ fontSize: '0.8em', textAlign: 'right' }}
                >
                  <props.children competitor={competitor} year={challenge} />
                </td>
              ))}
            </>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const filterByRange = (range?: [number, number]) => {
  if (!range) return () => true;
  return (y: number) => y >= range[0] && y <= range[1];
};

const YearByCompetitorTable: React.FC<{
  yearRange?: [number, number];
  children: React.FC<{ competitor: string; year: number }>;
  potentialDemand: (year: number) => number;
}> = props => {
  const years = useStore(s => s.challenges()).filter(
    filterByRange(props.yearRange)
  );
  const competitors = useStore(s => s.competitors());

  return (
    <table style={{ tableLayout: 'fixed', width: '150%' }}>
      <thead>
        <tr
          style={{
            verticalAlign: 'top',
            lineHeight: '1em',
          }}
        >
          <>
            <th style={{ width: '2%' }}>Y</th>
            <th style={{ width: '10%', textAlign: 'right' }}>
              Potential Demand
            </th>
            <th style={{ width: '12%' }}></th>
            {competitors.map(competitor => (
              <th style={{ textAlign: 'right' }} key={competitor}>
                {competitor}
              </th>
            ))}
          </>
        </tr>
      </thead>
      <tbody>
        {years.map((year, i) => (
          <tr key={year} style={{ background: i % 2 ? '#eee' : 'white' }}>
            <>
              <td>Y{year}</td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                {units(props.potentialDemand(year))} u
              </td>
              <td style={{ fontSize: '0.8em', textAlign: 'right' }}>
                <div>product price</div>
                <div>units sold</div>
                <div>market share</div>
              </td>
              {competitors.map(competitor => (
                <td
                  key={competitor}
                  style={{ fontSize: '0.8em', textAlign: 'right' }}
                >
                  <props.children competitor={competitor} year={year} />
                </td>
              ))}
            </>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const MarketSegmentDetail: React.FC<{
  segmentId: string;
  year: number;
  competitor: string;
}> = props => {
  const offer = useStore(s => s.offer(props.year, props.competitor));

  if (!offer) return <span>??</span>;

  const segment = offer.segmentsById[props.segmentId];

  return (
    <div title={printFeatureSet(offer.featureSet)}>
      <div>{priceInK(offer.price)}</div>
      <div>{units(segment.unitsSold)}</div>
      <div>{percent(segment.marketShare)}</div>
    </div>
  );
};

const printFeatureSet = (featureSet: Record<string, string>) => {
  return FeatureIds.map(f => `${f}: ${featureSet[f]}`).join('\n');
};

const MarketSegmentDetailLong: React.FC<{
  segmentId: string;
  year: number;
  competitor: string;
}> = props => {
  const offer = useStore(s => s.offer(props.year, props.competitor));

  if (!offer) return <span>??</span>;

  const segment = offer.segmentsById[props.segmentId];

  return (
    <>
      <div>{priceInK(offer.price)}</div>
      <div>{segment.id}</div>
      <div>{units(segment.potentialDemand)}</div>
      <div>--</div>
      <div>Utility</div>
      <div>fs: {param(segment.perceivedUtility.fromFeatureSet)}</div>
      <div>pp: {param(segment.perceivedUtility.pricePositioningEffect)}</div>
      <div>ph: {param(segment.perceivedUtility.priceHarmfulEffect)}</div>
      <div>=</div>
      <div>f: {param(segment.perceivedUtility.final)}</div>
      <div>--</div>
      <div>{units(segment.unitsSold)}</div>
      <div>{percent(segment.marketShare)}</div>
    </>
  );
};
