import { FeatureLevel } from './feature-level';
import { FeatureAvailableOptions } from './feature-available-options';

export const FeatureStatusRecap: React.FC<{
  featureIds: string[];
}> = props => (
  <table style={{ tableLayout: 'fixed', width: '100%' }}>
    <thead>
      <tr
        style={{
          verticalAlign: 'top',
          lineHeight: '1em',
        }}
      >
        <th style={{ width: '15%' }}>Feature</th>
        <th style={{ width: '10%', textAlign: 'center' }}>Lev.</th>
        <th style={{ width: '45%' }}>Available Options</th>
      </tr>
    </thead>
    <tbody>
      {props.featureIds.map(featureId => (
        <tr key={featureId}>
          <td>{featureId}</td>
          <td style={{ textAlign: 'center' }}>
            <FeatureLevel featureId={featureId} />
          </td>
          <td>
            <FeatureAvailableOptions featureId={featureId} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);
