import React from 'react';
import { Instructions } from '../layout/instructions';
import { Interaction } from '../layout/interaction';
import { Param } from '../layout/param';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { MarketSegmentsSummaryTable } from '../market/market-segments-summary-table';
import { TaskListByCategory } from '../tasks/task-list-by-category';
import { Term } from '../term';

export const InvestigateMarket = () => (
  <>
    <SectionTitle id="investigate-the-market">
      Tasks to Investigate the Market
    </SectionTitle>
    <p>
      Learn more about what new technologies are out there that can help you
      make a better, safer, more appealing and more performing product to your
      customer.
    </p>

    <p>
      Decide on what features you wish to invest - you’ll have to start{' '}
      <b>from the lower levels</b> before you can get to the highest ones.
    </p>

    <p>
      <b>Always be mindful of how many tasks you’re running in parallel.</b>
    </p>

    <p>
      The <Term lemma="Market" /> is divided in two <Term lemma="Segments" />:{' '}
      <Term lemma="Luxury" /> and <Term lemma="Mass" />.
    </p>

    <h3 id="demand-and-preference">Demand and Expected Preference Report</h3>
    <p>
      This report helps you keep track of the potential demand, gives you an
      overview on what Product’s Features are most wanted, and what price the
      customer seems currently willing to pay for a car. Information is given
      for both segments: Luxury and Mass.
    </p>

    <Report>
      <MarketSegmentsSummaryTable />
    </Report>

    <h3>Investigation</h3>
    <p>
      Use the following Tasks if you want to incrementally sharpening your
      expected preference distribution on a specific Feature.
    </p>

    <Interaction>
      <TaskListByCategory categoryId="investigate-market" />
    </Interaction>
  </>
);
