import produce from 'immer';
import { GetState, SetState } from 'zustand';
import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { IMarket } from '../concepts/market';
import { IProduct } from '../concepts/product';
import {
  AcquireDesignerPayload,
  ExpandCapacityPayload,
  ImproveBrandAwarenessPayload,
  ImproveEfficiencyPayload,
  ImprovePayload,
  InvestigateMarketPayload,
  ITasks,
  TaskDescription,
} from '../concepts/tasks';
import { tasks } from '../data/tasks';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';
import { Writeable } from '../utils/writable';

export const create: StoreSlice<ITasks & IPrivate, IGame & ILogger> = (
  set,
  get
) => {
  return {
    tasks: tasks,
    startedTasks: [],
    completedTasks: [],
    runningTasks: [],
    runningTaskLimit: 5,

    tasksByCategory(categoryId) {
      const tasks = get().tasks;

      return Object.keys(tasks)
        .filter(id => tasks[id].categoryId === categoryId)
        .sort((a, b) => tasks[a].sequenceOrder - tasks[b].sequenceOrder);
    },

    taskById(taskId) {
      return get().tasks[taskId];
    },

    completeTask(taskId) {
      const task = get().taskById(taskId);

      get().log(`completed task «${taskId}»`);

      set(
        produce((s: Writeable<ITasks>) => {
          if (s.runningTasks) {
            s.runningTasks = s.runningTasks.filter(r => r.taskId !== taskId);
            s.completedTasks.push(taskId);
          }
        })
      );

      const executeTask = createTaskExecutor(task);
      executeTask(set, get);
    },

    runTask(taskId, startTime: number) {
      if (get().runningTasks.length >= get().runningTaskLimit) return;

      const task = get().taskById(taskId);
      const endTime = startTime + task.durationInSeconds * 1000;

      get().log(`run task «${taskId}», deadline: ${endTime}`);

      set(
        produce((s: ITasks) => {
          s.runningTasks.push({
            taskId,
            endTime,
          });

          if (!s.startedTasks.includes(taskId)) {
            s.startedTasks.push(taskId);
          }
        })
      );
    },

    endedTasks(at: number) {
      const runningTasks = get().runningTasks;

      return runningTasks.filter(r => r.endTime <= at).map(r => r.taskId);
    },

    timeToEndTask(taskId: string, from: number) {
      const runningTask = get().runningTasks.find(r => r.taskId === taskId);
      if (!runningTask) return 0;

      return runningTask.endTime - from;
    },

    completeAllEndedTasks(at: number) {
      get()
        .endedTasks(at)
        .forEach(t => get().completeTask(t));
    },

    unlockTask(taskId) {
      set(
        produce((s: ITasks) => {
          s.tasks[taskId].isLocked = false;
        })
      );
    },

    lockTask(taskId) {
      set(
        produce((s: ITasks) => {
          s.tasks[taskId].isLocked = true;
        })
      );
    },

    increaseTaskCost(taskId: string, rate: number) {
      set(
        produce((s: ITasks) => {
          s.tasks[taskId].cost *= 1 + rate;
        })
      );
    },

    increaseTaskDuration(taskId: string, rate: number) {
      set(
        produce((s: ITasks) => {
          s.tasks[taskId].durationInSeconds *= 1 + rate;
        })
      );
    },

    isTaskStarted(taskId) {
      return get().startedTasks.includes(taskId);
    },

    isTaskRunning(taskId) {
      return !!get().runningTasks.find(t => t.taskId === taskId);
    },

    isTaskLocked(taskId) {
      return get().tasks[taskId].isLocked;
    },

    isTaskCompleted(taskId: string) {
      if (get().tasks[taskId].isReusable) return false;
      return get().completedTasks.includes(taskId);
    },
  };
};

interface IPrivate {}

export const serialize = (s: DeepPartial<ITasks & IPrivate>) => ({});

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<ITasks & IPrivate> => s;

interface ITaskExecutor {
  (set: SetState<any>, get: GetState<any>): void;
}

function createTaskExecutor(task: TaskDescription): ITaskExecutor {
  return compose([
    genericTaskUnlocks(task),
    genericTaskCostBearing(task),
    specificTaskExecutor(task),
  ]);
}

function specificTaskExecutor(task: TaskDescription): ITaskExecutor {
  switch (task.categoryId) {
    case 'investigate-market':
      return investigateMarketTask(task);

    case 'acquire-designer':
      return acquireDesignerTask(task);

    case 'improve':
      return improveTask(task);

    case 'improve-efficiency':
      return improveEfficiencyTask(task);

    case 'expand-capacity':
      return expandCapacityTask(task);

    case 'improve-brand-awareness':
      return improveBrandAwarenessTask(task);

    default:
      return mockTask(task.taskId);
  }
}
const compose =
  (executors: ITaskExecutor[]): ITaskExecutor =>
  (set, get) => {
    executors.forEach(e => e(set, get));
  };

const mockTask =
  (taskId: string) => (set: SetState<ILogger>, get: GetState<ILogger>) => {
    get().log(`mock task ${taskId} eseguita!`);
  };

const genericTaskUnlocks =
  (task: TaskDescription) => (set: SetState<ITasks>, get: GetState<ITasks>) => {
    task.unlocks.forEach(t => {
      if (get().tasks[t]) {
        get().unlockTask(t);
      }
    });
  };

const genericTaskCostBearing =
  (task: TaskDescription) =>
  (set: SetState<IProduct>, get: GetState<IProduct>) => {
    set(
      produce((s: IProduct) => {
        s.costForRunningTasks += task.cost;
      })
    );
  };

const investigateMarketTask =
  (task: TaskDescription<InvestigateMarketPayload>) =>
  (set: SetState<IMarket>, get: GetState<IMarket & ITasks>) => {
    get().setInformationNoise(task.payload.featureId, task.payload.noiseLevel);
    get().lockTask(task.taskId);
  };

const acquireDesignerTask =
  (task: TaskDescription<AcquireDesignerPayload>) =>
  (set: SetState<IProduct>, get: GetState<IProduct & ITasks>) => {
    get().unlockFeatureOption('design', task.payload.categories[0]);
    get().lockTask(task.taskId);
  };

const improveTask =
  (task: TaskDescription<ImprovePayload>) =>
  (set: SetState<IProduct>, get: GetState<IProduct & ITasks>) => {
    get().increaseFeatureLevel(
      task.payload.featureId,
      task.payload.levelIncrement
    );
    get().increaseTaskCost(task.taskId, task.payload.costIncrementRate);
    get().increaseTaskDuration(task.taskId, task.payload.durationIncrementRate);

    if (get().featureLevel(task.payload.featureId) >= 100) {
      get().lockTask(task.taskId);
    }
  };

const improveEfficiencyTask =
  (task: TaskDescription<ImproveEfficiencyPayload>) =>
  (set: SetState<IProduct>, get: GetState<IProduct & ITasks>) => {
    get().improveManufacturingEfficiency(task.payload.manufacturingEfficiency);
    set(
      produce((s: IProduct) => {
        s.learningRate += task.payload.learningRate;
        s.extraCostForEfficiencyImprovement +=
          task.payload.fixedAnnualCostIncrement;
      })
    );
    get().lockTask(task.taskId);
  };

const expandCapacityTask =
  (task: TaskDescription<ExpandCapacityPayload>) =>
  (set: SetState<IProduct>, get: GetState<IProduct & ITasks>) => {
    get().improveScaleEfficiency(task.payload.manufacturingEfficiency);
    get().incrementCapacity(task.payload.capacityIncrease);
    set(
      produce((s: IProduct) => {
        s.extraCostForCapacity += task.payload.fixedAnnualCostIncrement;
      })
    );
    get().lockTask(task.taskId);
  };

const improveBrandAwarenessTask =
  (task: TaskDescription<ImproveBrandAwarenessPayload>) =>
  (set: SetState<IProduct>, get: GetState<IProduct & ITasks>) => {
    set(
      produce((s: IProduct) => {
        s.brandAwareness += task.payload.brandAwarenessLevel;
      })
    );
    get().lockTask(task.taskId);
  };
