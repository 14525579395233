import { ChallengeDuration } from '../clock/challenge-duration';
import { SprintDuration } from '../clock/sprint-duration';
import { WindowOfOpportunityCount } from '../clock/window-opportunity-count';
import { WindowOfOpportunityDuration } from '../clock/window-opportunity-duration';
import { Instructions } from '../layout/instructions';
import { Param } from '../layout/param';
import { SectionTitle } from '../layout/section-title';
import { Term } from '../term';

export const GameRules = () => {
  return (
    <>
      <SectionTitle id="game-rules">Game Rules</SectionTitle>
      <h3>1. Time</h3>
      <p>
        The game is structured in{' '}
        <Param>
          <WindowOfOpportunityCount />
        </Param>{' '}
        consecutive <Term lemma="Windows-Of-Opportunity" /> of{' '}
        <Param>
          <WindowOfOpportunityDuration />
        </Param>{' '}
        of duration each.
      </p>
      <p>
        A <Term lemma="Window-Of-Opportunity" /> corresponds to 1 year in game
        time.
      </p>
      <div>
        Every window is subdivided in two sections:{' '}
        <ul>
          <li>
            <Term lemma="Sprint" /> (4 minutes); and
          </li>
          <li>
            <Term lemma="Challenge" /> (1 minute).
          </li>
        </ul>
      </div>
      <p>
        At the very end of each Sprint, if you have decided to launch your
        product on the market, you will submit an <Term lemma="Offer" /> to the
        market and start selling your product. Results will appear during the
        upcoming Challenge.
      </p>
      <p>
        The Market is divided in two Segments: <Term lemma="Luxury" /> and{' '}
        <Term lemma="Mass" />.
      </p>
      <p>
        During a <Term lemma="Challenge" />, the game will provide you with new
        infos (in the forms of Reports) about the decisions taken by all the
        players involved during the year.
      </p>
      <h3>2. Decisions</h3>
      <p>
        During each <Term lemma="Sprint" /> you’ll be asked to take a series of
        strategic decisions.
      </p>
      <p>
        Some Decisions are related to <b>Defining your Product</b> (a Product is
        made up of 5 Features) and <b>Competing on the Market</b>.
      </p>
      <p>
        Other types of decisions, called <Term lemma="Tasks" />, can help you
        improve your product, your production plant, and boost your brand
        awareness.
      </p>
      <div>
        <u>You can run Tasks related to:</u>

        <div style={{ paddingLeft: '2em' }}>
          <p>
            <b>1. Investigating the Market</b> - or learning about the market’s
            preferences. What features the market likes more.
          </p>
          <p>
            <b>2. Acquiring Know-How</b> - or being able to unlock new features’
            options, that you can use to set up new configurations of your
            product.
          </p>
          <p>
            <b>3. Optimizing your Production Plant</b> - or improving your
            Efficiency and Capacity. Efficiency will help boost both your
            Manufacturing Efficiency and your Learning Rate. Investing in your
            plant’s production capacity also allows you to improve your
            Economies of Scale. Both these kinds of tasks can help you lower
            your cost per unit.
          </p>
          <p>
            <b>4. Boost your Brand Awareness</b> - making yourself better known
            on the market will help you gain a bigger slice of the pie. You must
            make yourself visible to the market before you can start selling
            your product.
          </p>
        </div>
      </div>
      <p>
        Each Task costs money and time. You can run up to 5 Tasks
        simultaneously.
      </p>
      <h3>3. Reports</h3>

      <p>
        Each section of the game provides you with a series of <b>Reports</b>
        (highlighted in blue) to help you assess your company’s results and how
        the market is responding to decisions taken by all the players involved.
      </p>

      <p>
        Reports are particularly valuable to determine what Tasks to perform to
        improve/modify your product and what offer to submit.
      </p>

      <h3>4. Who Wins</h3>

      <p>
        The Team that reaches the highest possible operating income wins the
        game. The final section - <b>Analyze Results</b> - can help you keep
        track of it.
      </p>
    </>
  );
};
