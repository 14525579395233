export const IndexOfSections = () => (
  <>
    <h5>Index of Sections</h5>
    <ul>
      <li>
        <a href="#background">The Background</a>
      </li>
      <li>
        <a href="#game-rules">Game Rules</a>
      </li>
      <li>
        <a href="#define-your-product">Define your Product</a>
      </li>
      <li>
        <a href="#compete-on-the-market">Compete on the Market</a>
      </li>
      <li>
        <a href="#investigate-the-market">Investigate the Market</a>
      </li>
      <li>
        <a href="#acquire-know-how">Acquire Know How</a>
      </li>
      <li>
        <a href="#optimize-production">Optimize Production</a>
      </li>
      <li>
        <a href="#boost-brand-awareness">Boost Brand Awareness</a>
      </li>
      <li>
        <a href="#analyze-results">Analyze Results</a>
      </li>
    </ul>
  </>
);
