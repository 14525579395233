import { useStore } from '../../store';
import { level } from '../layout/formatters';
import { ListOfWords } from '../layout/list-of-words';

export const FeatureAvailableOptions: React.FC<{
  featureId: string;
}> = props => {
  const availableOptions = useStore(s =>
    s.featureAvailableOptions(props.featureId)
  );

  return (
    <span>
      <ListOfWords>{availableOptions.map(o => o.optionId)}</ListOfWords>
    </span>
  );
};
