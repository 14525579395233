import { useStore } from '../../store';
import { balance } from '../layout/formatters';

export const IncomeStatementReport: React.FC<{ year: number }> = props => {
  const r = useStore(s => s.productionVolumeReportByYear(props.year));

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead>
        <tr>
          <th style={{ width: '70%' }}></th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(f => (
          <tr key={f.id}>
            <th>{f.display}</th>
            <td>{Number.isNaN(r[f.id]) ? 'NaN' : f.format(r[f.id])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const fields = [
  { id: 'revenue', format: balance, display: 'Revenue' },
  {
    id: 'totalVariableCosts',
    format: balance,
    display: 'Total Variable Costs',
  },
  {
    id: 'totalFixedCosts',
    format: balance,
    display: 'Total Fixed Costs',
  },
  {
    id: 'totalCosts',
    format: balance,
    display: 'Total Costs',
  },
  {
    id: 'operatingIncome',
    format: balance,
    display: 'Operating Income',
  },
];

export const MultiYearIncomeStatementReport: React.FC = props => {
  const years = useStore(s => s.challenges());

  return (
    <table style={{ tableLayout: 'fixed' }}>
      <thead>
        <tr
          style={{
            verticalAlign: 'top',
            lineHeight: '1em',
          }}
        >
          <>
            <th style={{ width: '20%' }}>#</th>
            {years.map(year => (
              <th style={{ textAlign: 'right' }} key={year}>
                {year}
              </th>
            ))}
          </>
        </tr>
      </thead>
      <tbody>
        {fields.map(f => (
          <tr key={f.id}>
            <th>{f.display}</th>
            {years.map(year => {
              const r = useStore(s => s.incomeStatementByYear(year));

              return (
                <td
                  key={year}
                  style={{ textAlign: 'right', fontSize: '0.9em' }}
                >
                  {r
                    ? Number.isNaN(r[f.id])
                      ? 'NaN'
                      : f.format(r[f.id])
                    : '?'}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
