import { ILogger } from '../concepts/logger';
import {
  FeatureIds,
  FeatureOption,
  FeatureOptions,
  FixedCostsReport,
  IncomeStatement,
  IProduct,
  LearningReport,
  ProductionCapacityReport,
  ProductionVolumeReport,
  VariableCostsReport,
} from '../concepts/product';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';
import produce from 'immer';
import { featureOptions } from '../data/feature-options';
import { IChallenges } from '../concepts/challenges';
import { IPlayer } from '../concepts/player';

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  Query,
} from 'firebase/firestore';
import { Offer } from '../concepts/market';
const firebaseConfig = {
  apiKey: 'AIzaSyD5uIACipLEc7ov_x94Ceih2hocEcFosgg',
  authDomain: 'high-voltage-c338e.firebaseapp.com',
  projectId: 'high-voltage-c338e',
  storageBucket: 'high-voltage-c338e.appspot.com',
  messagingSenderId: '926285356853',
  appId: '1:926285356853:web:4f2a02fbf98abb465a767c',
};

const fb = initializeApp(firebaseConfig);
const db = getFirestore(fb);

export const create: StoreSlice<
  IProduct & IPrivate,
  ILogger & IChallenges & IPlayer
> = (set, get) => {
  return {
    /** feature set */
    _featureOptions: featureOptions,
    featureSet: nullFeatureSet(),
    featuresLevel: baseFeatureLevel(),

    featureOptions(featureId) {
      return get()._featureOptions.filter(o => o.featureId === featureId);
    },

    featureAvailableOptions(featureId: string) {
      return get()
        .featureOptions(featureId)
        .filter(o => o.isAvailable);
    },

    unsetFeatures() {
      return FeatureIds.filter(id => get().featureSet[id] === null);
    },

    assignedFeatureOption(featureId) {
      return get().featureSet[featureId] || '';
    },

    featuresIds() {
      return FeatureIds.map(id => id);
    },

    featureLevel(featureId) {
      return get().featuresLevel[featureId];
    },

    isFeatureSelectable(featureId) {
      if (['design', 'size'].includes(featureId) && get().isProductLaunched)
        return false;

      return true;
    },

    assignOptionToFeature(featureId, optionId) {
      set(
        produce((s: IProduct) => {
          s.featureSet[featureId] = optionId || null;
        })
      );
    },

    unlockFeatureOption(featureId, optionId) {
      set(
        produce((s: IProduct & IPrivate) => {
          const i = s._featureOptions.findIndex(
            f => f.featureId === featureId && f.optionId === optionId
          );
          s._featureOptions[i].isAvailable = true;
        })
      );
    },

    increaseFeatureLevel(featureId, inc) {
      const nextLevel = get().featuresLevel[featureId] + inc;

      set(
        produce((s: IProduct & IPrivate) => {
          s.featuresLevel[featureId] = nextLevel;
        })
      );

      if (nextLevel >= 75)
        get().unlockFeatureOption(featureId, FeatureOptions[featureId][3]);
      else if (nextLevel >= 50)
        get().unlockFeatureOption(featureId, FeatureOptions[featureId][2]);
      else if (nextLevel >= 25)
        get().unlockFeatureOption(featureId, FeatureOptions[featureId][1]);
    },

    /** variable economics */

    baseCostPerUnit: 7_500,
    price: 0,

    baseCostAfterImprovement() {
      return get().baseCostPerUnit * (1 - get().manufacturingEfficiency);
    },

    featureExtraCost(featureId) {
      const featureOption = get()._featureOptions.find(
        o =>
          o.featureId === featureId &&
          o.optionId === get().featureSet[featureId]
      );

      if (!featureOption) return 0;
      return featureOption.extraCostPerUnit;
    },

    featureExtraFixedCost(featureId) {
      const featureOption = get()._featureOptions.find(
        o =>
          o.featureId === featureId &&
          o.optionId === get().featureSet[featureId]
      );

      if (!featureOption) return 0;
      return featureOption.extraFixedCost;
    },

    totalFeatureExtraCost() {
      return FeatureIds.reduce(
        (total, id) => total + get().featureExtraCost(id),
        0
      );
    },

    totalCostBeforeEconomyOfScale() {
      return get().baseCostAfterImprovement() + get().totalFeatureExtraCost();
    },

    totalCostAfterEconomyOfScale() {
      return (
        get().totalCostBeforeEconomyOfScale() * (1 - get().economyOfScale())
      );
    },

    totalCostBeforeEconomyOfLearning() {
      return get().totalCostAfterEconomyOfScale();
    },

    totalCostAfterEconomyOfLearning() {
      return (
        get().totalCostBeforeEconomyOfLearning() *
        (1 - get().economyOfLearning())
      );
    },

    totalCost() {
      return get().totalCostAfterEconomyOfLearning();
    },

    minimumPrice() {
      return get().totalCost() * 0.8;
    },

    effectivePrice() {
      return Math.max(get().minimumPrice(), get().price);
    },

    isPriceUnderCost() {
      return get().totalCost() > get().effectivePrice();
    },

    toVariableCostReport() {
      return toVariableCostReport(get());
    },

    assignPrice(price) {
      set({
        price: Math.round(price / 500) * 500,
      });
    },

    /** fixed economics */
    baseFixedCosts: 500_000_000,
    extraCostForCapacity: 0,
    extraCostForDesign: 0,
    extraCostForEngineering: 0,
    extraCostForPerformance: 0,
    extraCostForRange: 0,
    extraCostForSafety: 0,
    costForRunningTasks: 0,
    extraCostForEfficiencyImprovement: 0,

    incomeStatementsByYear: [],

    totalFixedCosts() {
      return (
        get().baseFixedCosts +
        get().extraCostForCapacity +
        get().extraCostForDesign +
        get().extraCostForEngineering +
        get().extraCostForRange +
        get().extraCostForSafety +
        get().costForRunningTasks +
        get().extraCostForEfficiencyImprovement
      );
    },

    incomeStatementByYear(year: number) {
      return get().incomeStatementsByYear[year];
    },

    toFixedCostReport() {
      return toFixedCostReport(get());
    },

    /** learning effect */

    learningRate: 0,

    economyOfLearning() {
      if (get().cumulativeProduction() < get().standardCapacity) return 0;
      return (
        get().learningRate *
        Math.log(get().cumulativeProduction() / get().standardCapacity)
      );
    },

    toLearningReport() {
      return toLearningReport(get());
    },

    /** launch */

    isProductLaunched: false,

    canProductBeLaunched() {
      return FeatureIds.every(id => get().featureSet[id] !== null);
    },

    launchProduct() {
      set({
        isProductLaunched: true,
      });
    },

    /** production */
    standardCapacity: 100_000,
    capacityImprovement: 0,
    producedUnitsByYear: [],
    manufacturingEfficiency: 0,
    scaleEfficiency: 0,
    productionVolumeReportsByYear: [],

    actualCapacity() {
      return get().standardCapacity * (1 + get().capacityImprovement);
    },

    economyOfScale() {
      return get().scaleEfficiency;
    },

    cumulativeProduction() {
      return get().producedUnitsByYear.reduce((sum, p) => sum + p, 0);
    },

    toProductionCapacityReport() {
      return toProductionCapacityReport(get());
    },

    productionVolumeReportByYear(year) {
      return get().productionVolumeReportsByYear[year];
    },

    improveManufacturingEfficiency(ratio: number) {
      set(s => ({
        manufacturingEfficiency: s.manufacturingEfficiency + ratio,
      }));
    },

    improveScaleEfficiency(ratio: number) {
      set(s => ({
        scaleEfficiency: s.scaleEfficiency + ratio,
      }));
    },

    incrementCapacity(ratio: number) {
      set(s => ({
        capacityImprovement: s.capacityImprovement + ratio,
      }));
    },

    produceAndSold(units: number, price: number, year: number) {
      set(
        produce((s: IProduct) => {
          const produced = Math.min(units, get().actualCapacity());

          s.producedUnitsByYear[year] = produced;
          s.incomeStatementsByYear[year] = toIncomeStatement(
            get(),
            produced,
            price
          );
          s.productionVolumeReportsByYear[year] = toProductionVolumeReport(
            get(),
            units,
            produced
          );
        })
      );
    },

    /** brand awareness */
    brandAwareness: 0,

    /** competition (move directly to IMarket?) */
    offerSubmitted: false,

    submitOffer: (challenge: number) => {
      if (get().isProductLaunched && !get().offerSubmitted) {
        get().log(`submitting offer to challenge «${challenge}»`);

        set({
          offerSubmitted: true,
        });

        const ref = doc(
          db,
          `offers-${get().gameId}`,
          `${[get().playerId, challenge].join(':')}`
        );

        setDoc(ref, {
          competitor: get().playerId,
          featureSet: get().featureSet,
          price: get().effectivePrice(),
          brandAwarenessEffect: 0 /** @TODO: replace with actual value */,
          year: challenge,
        })
          .then(() => console.log('DONE'))
          .catch(err => console.error(err));
      }
    },

    updateChallengeResults(challenge: number) {
      get().log(`updating challenge results «${challenge}»`);

      set({
        offerSubmitted: false,
      });

      const q = query(
        collection(db, `offers-${get().gameId}`),
        where('year', '==', challenge)
      );

      getDocs(q).then(s => {
        get().log(`«${challenge}» ${s.docs.map(d => d.id).join(', ')}`);

        s.forEach(doc => {
          get().addOfferToChallenge(challenge, doc.data() as Offer);
        });
        get().commitChallengeResults(challenge);
      });
    },
  };
};

interface IPrivate {
  _featureOptions: FeatureOption[];
}

export const serialize = (s: DeepPartial<IProduct & IPrivate>) => s;

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<IProduct & IPrivate> => s;

const baseFeatureSet = () => ({
  design: 'cheap',
  size: 'compact',
  performance: 'slow',
  range: 'city',
  safety: 'adequate',
});

const nullFeatureSet = () => ({
  design: null,
  size: null,
  performance: null,
  range: null,
  safety: null,
});

const baseFeatureLevel = () => ({
  design: 1,
  size: 1,
  performance: 1,
  range: 1,
  safety: 1,
});

const toIncomeStatement = (
  p: IProduct,
  producedUnits: number,
  price: number
): IncomeStatement => {
  const totalFixedCosts = p.totalFixedCosts();
  const totalVariableCosts = p.totalCost() * producedUnits;
  const totalCosts = totalFixedCosts + totalVariableCosts;
  const revenue = price * producedUnits;
  const operatingIncome = revenue - totalCosts;

  return {
    baseFixedCosts: p.baseFixedCosts,
    extraCostForCapacity: p.extraCostForCapacity,
    extraCostForDesign: p.extraCostForDesign,
    extraCostForEngineering: p.extraCostForEngineering,
    extraCostForPerformance: p.extraCostForPerformance,
    extraCostForRange: p.extraCostForRange,
    extraCostForSafety: p.extraCostForSafety,
    costForRunningTasks: p.costForRunningTasks,
    extraCostForEfficiencyImprovement: p.extraCostForEfficiencyImprovement,

    baseCostPerUnit: p.baseCostPerUnit,
    baseCostAfterImprovement: p.baseCostAfterImprovement(),
    baseCostAfterFeatureCost: p.totalCostBeforeEconomyOfScale(),
    totalCostAfterEconomyOfScale: p.totalCostBeforeEconomyOfLearning(),
    totalCostAfterEconomyOfLearning: p.totalCost(),

    totalFixedCosts,
    totalVariableCosts,
    totalCosts,
    revenue,
    operatingIncome,
  };
};

const toFixedCostReport = (p: IProduct): FixedCostsReport => {
  return {
    baseFixedCosts: p.baseFixedCosts,
    extraCostForCapacity: p.extraCostForCapacity,
    extraCostForDesign: p.extraCostForDesign,
    extraCostForEngineering: p.extraCostForEngineering,
    extraCostForPerformance: p.extraCostForPerformance,
    extraCostForRange: p.extraCostForRange,
    extraCostForSafety: p.extraCostForSafety,
    costForRunningTasks: p.costForRunningTasks,
    extraCostForEfficiencyImprovement: p.extraCostForEfficiencyImprovement,

    totalFixedCosts: p.totalFixedCosts(),
  };
};

const toVariableCostReport = (p: IProduct): VariableCostsReport => {
  return {
    baseCostPerUnit: p.baseCostPerUnit,
    baseCostAfterImprovement: p.baseCostAfterImprovement(),

    extraCostForDesign: p.featureExtraCost('design'),
    extraCostForEngineering: p.featureExtraCost('size'),
    extraCostForPerformance: p.featureExtraCost('performance'),
    extraCostForRange: p.featureExtraCost('range'),
    extraCostForSafety: p.featureExtraCost('safety'),

    baseCostAfterFeatureCost: p.totalCostBeforeEconomyOfScale(),
    totalCostAfterEconomyOfScale: p.totalCostAfterEconomyOfScale(),
    totalCostAfterEconomyOfLearning: p.totalCostAfterEconomyOfLearning(),

    manufacturingEfficiency: p.manufacturingEfficiency,
    economyOfScale: p.economyOfScale(),
    economyOfLearning: p.economyOfLearning(),
  };
};

const toLearningReport = (p: IProduct): LearningReport => {
  return {
    learningRate: p.learningRate,
    cumulativeProduction: p.cumulativeProduction(),
    economyOfLearning: p.economyOfLearning(),
    costPerUnitAssumingFullCapacity: 0,
    costStavingDueToLearning: 0,
  };
};

const toProductionVolumeReport = (
  p: IProduct,
  demand: number,
  productionVolume: number
): ProductionVolumeReport => {
  return {
    standardCapacity: p.standardCapacity,
    capacityImprovement: p.capacityImprovement,
    actualCapacity: p.actualCapacity(),
    demand,
    productionVolume,
  };
};

const toProductionCapacityReport = (p: IProduct): ProductionCapacityReport => {
  return {
    standardCapacity: p.standardCapacity,
    capacityImprovement: p.capacityImprovement,
    actualCapacity: p.actualCapacity(),
  };
};
