import React from 'react';
import { FeatureIds } from '../../store/concepts/product';
import { Interaction } from '../layout/interaction';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { FeatureStatusRecap } from '../product/feature-status-recap';
import { TaskListByCategory } from '../tasks/task-list-by-category';

export const AcquireKnowHow = () => (
  <>
    <SectionTitle id="acquire-know-how">Tasks to Acquire Know-How</SectionTitle>
    <p>Get the best Teams available according to your product and customers.</p>

    <p>
      You might want to start with selecting your Design Team first - if you’re
      strategically aiming towards acquiring the best designing options you want
      to move fast. Great designers are hard to come by.
    </p>

    <p>
      You can also invest in “training” your Tech Teams by selecting Tasks that
      will help you discover new technologies (or Options) for the other
      features: size, performance, range, and safety.
    </p>

    <p>
      You can see a recap of the benefits of your investment in the Define your
      Product section. Once acquired, new options will appear there.
    </p>

    <h3>Design</h3>

    <Interaction>
      <TaskListByCategory categoryId="acquire-designer" />
    </Interaction>

    <h3>Technology</h3>
    <p>
      Use the following Task to acquire expertize on a specific technology and
      gathering Feature Levels. Every 25 Levels you will unlock new Feature
      Options.
    </p>

    <Interaction>
      <TaskListByCategory categoryId="improve" />
    </Interaction>

    <Report>
      <FeatureStatusRecap featureIds={FeatureIds.filter(f => f !== 'design')} />
    </Report>
  </>
);
