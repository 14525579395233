import numeral from 'numeral';
import { useStore } from '../../store';
import { RTD } from '../utils/rtd';

export const FeatureOptionPreferenceRow: React.FC<
  FeatureOptionPreferenceRowProps
> = props => {
  const luxury = useStore(s =>
    s.featurePreference('luxury', props.featureId, props.optionId)
  );

  const mass = useStore(s =>
    s.featurePreference('mass', props.featureId, props.optionId)
  );

  return (
    <tr>
      <td style={{ textIndent: '2em' }}>{props.optionId}</td>
      <RTD>{numeral(luxury).format('0 %')}</RTD>
      <RTD>{numeral(mass).format('0 %')}</RTD>
    </tr>
  );
};

type FeatureOptionPreferenceRowProps = {
  readonly featureId: string;
  readonly optionId: string;
};
