import { IPlayer } from '../concepts/player';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<IPlayer & IPrivate> = (set, get) => {
  return {
    gameId: 'G1',
    playerId: 'P1',
  };
};

interface IPrivate {}

export const serialize = (s: DeepPartial<IPlayer & IPrivate>) => ({});

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<IPlayer & IPrivate> => s;
