import { useEffect, useState } from 'react';
import { useStore } from '../../store';
import qs from 'query-string';
import { Alert, Container } from 'react-bootstrap';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const Initializer = ({ children }: IProps): JSX.Element => {
  const init = useStore(s => s.init);
  const isInitialized = useStore(s => s.isInitialized);

  const [parsedGameId, setParsedGameId] = useState<string>();
  const [parsedPlayerId, setParsedPlayerId] = useState<string>();
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (isInitialized) return;

    const parsed = qs.parse(location.search);

    const gameId: string = parsed?.g?.toString();
    const playerId: string = parsed?.p?.toString();

    setParsedGameId(gameId);
    setParsedPlayerId(playerId);

    if (!gameId || !playerId) return;

    useStore.persist.setOptions({ name: `high-voltage-${gameId}:${playerId}` });

    useStore.persist.rehydrate().then(() => {
      init({
        gameId,
        playerId,
        challengeDurationInSeconds: 60,
        sprintDurationInSeconds: 240,
        totalWindowsOfOpportunities: 8,
      });

      setReady(true);
    });
  }, []);

  // If everything is ready, render the children.
  if (isReady) return <>{children}</>;

  // If we're not ready, and no game id was retrieved, then we're should not be
  // here.
  if (!parsedGameId) return <p>Invalid link.</p>;
  // If we're not ready, but we have a game id and a player id, then it's a
  // matter of waiting.
  if (parsedPlayerId) return <p>Redirecting</p>;

  // If we're here, then we have a game id, but no player id. Let's show a
  // selector to let the user pick a player.
  return (
    <Container>
      <h1>Please pick a player to proceed</h1>

      <br />

      {Array.from({ length: 10 }, (_, index) => (
        <Alert
          key={index}
          variant="info"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (typeof window === 'undefined') return;

            const baseURL: string = window.location
              .toString()
              .replace(/[^\/]+\/?$/, '');

            window.location.assign(
              `${baseURL}?g=${parsedGameId}&p=P${index + 1}`
            );
          }}
        >
          Play as Player {index + 1}
        </Alert>
      ))}
    </Container>
  );
};
