import Immutable from 'immutable';

export interface ITasks {
  tasks: Record<string, TaskDescription>;
  startedTasks: string[];
  completedTasks: string[];
  runningTasks: RunningTask[];
  runningTaskLimit: number;

  tasksByCategory(categoryId: string): string[];
  taskById(taskId: string): TaskDescription;
  endedTasks(at: number): string[];
  isTaskStarted(taskId: string): boolean;
  isTaskLocked(taskId: string): boolean;
  isTaskCompleted(taskId: string): boolean;
  isTaskRunning(taskId: string): boolean;
  timeToEndTask(taskId: string, from: number): number;

  completeTask(taskId: string): void;
  runTask(taskId: string, startTime: number): void;
  completeAllEndedTasks(at: number): void;
  unlockTask(taskId: string): void;
  lockTask(taskId: string): void;
  increaseTaskCost(taskId: string, rate: number): void;
  increaseTaskDuration(taskId: string, rate: number): void;
}

export type TaskDescription<P = any> = {
  taskId: string;
  categoryId: string;
  displayName: string;
  toolTip: string;
  cost: number;
  durationInSeconds: number;
  unlocks: string[];
  sequenceOrder: number;
  isLocked: boolean;
  isReusable: boolean;
  payload: P;
};

export type RunningTask = {
  taskId: string;
  endTime: number;
};

export type TaskBuilder = Record<string, (taskId: string) => TaskDescription>;

export function extractTask(
  builder: TaskBuilder
): Record<string, TaskDescription> {
  return Immutable.Map(builder)
    .map((task, id) => task(id))
    .toJSON();
}

const task =
  (categoryId: string) =>
  <P>(payload: P) =>
  (opts: {
    cost: number;
    durationInSeconds: number;
    unlocks?: string[];
    sequenceOrder?: number;
    isLocked?: boolean;
    isReusable?: boolean;
  }) =>
  (displayName: string, toolTip: string = '') =>
  (taskId: string) => ({
    taskId,
    categoryId,
    displayName,
    unlocks: [],
    sequenceOrder: 0,
    isLocked: false,
    isReusable: false,
    toolTip,
    ...opts,
    payload,
  });

export const investigateMarket = (payload: InvestigateMarketPayload) =>
  task('investigate-market')(payload);

export type InvestigateMarketPayload = {
  featureId: string;
  noiseLevel: number;
};

export const acquireDesigner = (payload: AcquireDesignerPayload) =>
  task('acquire-designer')(payload);

export type AcquireDesignerPayload = {
  categories: string[];
};

export const improve = (payload: ImprovePayload) => task('improve')(payload);

export type ImprovePayload = {
  featureId: string;
  levelIncrement: number;
  costIncrementRate: number;
  durationIncrementRate: number;
};

export const improveEfficiency = (payload: ImproveEfficiencyPayload) =>
  task('improve-efficiency')(payload);

export type ImproveEfficiencyPayload = {
  manufacturingEfficiency: number;
  learningRate: number;
  fixedAnnualCostIncrement: number;
};

export const expandCapacity = (payload: ExpandCapacityPayload) =>
  task('expand-capacity')(payload);

export type ExpandCapacityPayload = {
  manufacturingEfficiency: number;
  capacityIncrease: number;
  fixedAnnualCostIncrement: number;
};

export const improveBrandAwareness = (payload: ImproveBrandAwarenessPayload) =>
  task('improve-brand-awareness')(payload);

export type ImproveBrandAwarenessPayload = {
  brandAwarenessLevel: number;
};
