import { useStore } from '../../store';
import { RTD } from '../utils/rtd';
import { FeaturePreferenceSubTable } from './feature-preference-sub-table';
import { SegmentPotentialDemand } from './segment-potential-demand';

export const MarketSegmentsSummaryTable = () => {
  const year = useStore(s => s.currentSprint) + 1;

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead>
        <tr
          style={{
            verticalAlign: 'top',
            lineHeight: '1em',
          }}
        >
          <th style={{ width: '50%' }}></th>
          <th style={{ width: '25%', textAlign: 'right' }}>Luxury Seg.</th>
          <th style={{ width: '25%', textAlign: 'right' }}>Mass Seg.</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th colSpan={3}> </th>
        </tr>
        <tr>
          <th colSpan={3}>DEMAND</th>
        </tr>
        <tr>
          <th style={{ textIndent: '1em' }}>
            Y{year} Potential Demand (units)
          </th>
          <td style={{ textAlign: 'right' }}>
            <SegmentPotentialDemand segmentId="luxury" year={year} />
          </td>
          <td style={{ textAlign: 'right' }}>
            <SegmentPotentialDemand segmentId="mass" year={year} />
          </td>
        </tr>
        <tr style={{ textIndent: '1em' }}>
          <th>Y{year + 1} Potential Demand (units)</th>
          <td style={{ textAlign: 'right' }}>
            <SegmentPotentialDemand segmentId="luxury" year={year + 1} />
          </td>
          <td style={{ textAlign: 'right' }}>
            <SegmentPotentialDemand segmentId="mass" year={year + 1} />
          </td>
        </tr>

        <FeaturesPreferencesSubTable />

        <tr>
          <th colSpan={3} style={{ paddingTop: '1em' }}>
            PRICE PREFERENCE
          </th>
        </tr>
        <tr>
          <th style={{ textIndent: '1em' }}>Price Range (K$)</th>
          <RTD>50/80</RTD>
          <RTD>18/30</RTD>
        </tr>
      </tbody>
    </table>
  );
};

const FeaturesPreferencesSubTable = () => {
  const featuresIds = useStore(s => s.featuresIds());

  return (
    <>
      <tr>
        <th colSpan={3} style={{ paddingTop: '1em' }}>
          FEATURE OPTIONS PREFERENCES
        </th>
      </tr>
      {featuresIds.map((featureId, i) => (
        <FeaturePreferenceSubTable key={featureId} featureId={featureId} />
      ))}
    </>
  );
};
