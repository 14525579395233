import numeral from 'numeral';
import { useStore } from '../../store';

export const ProductEffectivePrice: React.FC<{}> = props => {
  const effectivePrice = useStore(s => s.effectivePrice());
  const isPriceUnderCost = useStore(s => s.isPriceUnderCost());

  return (
    <span
      style={{
        position: 'relative',
        fontWeight: 'bold',
        color: isPriceUnderCost ? 'red' : 'black',
      }}
    >
      {numeral(effectivePrice).format('$ 0,0.00')}/unit
    </span>
  );
};
