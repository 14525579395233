import { useStore } from '../../store';
import { ChallengeResultTable } from '../challenges/challenge-results-table';
import { Instructions } from '../layout/instructions';
import { Interaction } from '../layout/interaction';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { LaunchProductButton } from '../product/launch-product-button';
import { PriceUnderCostMessage } from '../product/price-under-cost-message';
import { ProductEffectivePrice } from '../product/product-effective-price';
import { ProductMinimumPrice } from '../product/product-minimum-price';
import { ProductPriceInput } from '../product/product-price-input';
import { ProductTotalCost } from '../product/product-total-cost';
import { Term } from '../term';

export const CompeteOnTheMarket = () => (
  <>
    <SectionTitle id="compete-on-the-market">
      Compete on the Market
    </SectionTitle>

    <p>This is the section in which you can launch your car on the market.</p>

    <p>
      Before you do so, you need to <b>set a price</b> - which cannot be lower
      than 80% of your total production. This is your{' '}
      <Term lemma="Minimum Price" />.
    </p>

    <p>
      Also, <b>make sure your car is not missing any features</b> before launch.
      Pick your Primary Feature wisely. You won’t be able to change them after
      launch.
    </p>

    <p>
      If you’re not satisfied with your current Product’s configuration, you
      might want to <b>Investigate the Market</b> a bit more.
    </p>

    <p>
      There is <b>1 report</b> available here to help you keep track of what the
      competition is doing.
    </p>

    <Interaction>
      <table style={{ tableLayout: 'fixed', width: '100%' }}>
        <tbody>
          <tr>
            <th style={{ width: '70%' }}>Product Price:</th>
            <td>
              <ProductPriceInput />
            </td>
          </tr>
          <tr>
            <th>Minimum Price:</th>
            <td>
              <ProductMinimumPrice />
            </td>
          </tr>
          <tr>
            <th>Effective Price:</th>
            <td>
              <ProductEffectivePrice />
            </td>
          </tr>
          <tr>
            <th>Cost per Unit:</th>
            <td>
              <ProductTotalCost />
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'right' }}>
              <PriceUnderCostMessage />
            </td>
          </tr>
        </tbody>
      </table>
    </Interaction>

    <h3>Launch to Market</h3>
    <p>
      Again, make sure your car is not missing any features! If you’re not happy
      with the current Product’s configuration either change it or acquire
      know-how for new options.
    </p>
    <LaunchProductSection />

    <h3 id="competitors-tracking">Competitors Tracking Report</h3>

    <p>
      This report helps you to keep track of your competitors: what prices
      they’ve offered to both segments, how many units of their products they
      have sold, how big is their slice of the market.
    </p>

    <Report>
      <ChallengeResultTable />
    </Report>
  </>
);

const LaunchProductSection = () => {
  const isProductLaunched = useStore(s => s.isProductLaunched);

  if (isProductLaunched)
    return (
      <>
        <p>The product has been launched already.</p>
      </>
    );

  return (
    <>
      <p>
        <Term lemma="launching" /> a product is a big deal: once it is done you
        can't go back.
      </p>

      <Interaction>
        <LaunchProductButton />
      </Interaction>
    </>
  );
};
