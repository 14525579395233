export const Story: React.FC<{
  children: React.ReactElement | React.ReactElement[];
}> = props => {
  return (
    <div
      style={{
        marginBottom: '1em',
      }}
    >
      {props.children}
    </div>
  );
};
