import { IChallenges } from '../concepts/challenges';
import { IGame } from '../concepts/game';
import { IMarket } from '../concepts/market';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';

import { IPlayer } from '../concepts/player';
import { ILogger } from '../concepts/logger';

export const create: StoreSlice<
  IChallenges & IPrivate,
  IGame & IMarket & IPlayer & ILogger
> = (set, get) => {
  return {
    challengeResults: {},

    addOfferToChallenge(challenge, offer) {
      get().log(
        `offer added to challenge «${challenge}», competitor: ${offer.competitor}`
      );
      get().issueOffer({
        ...offer,
        year: challenge,
      });
    },

    commitChallengeResults(challenge) {
      get().evaluateMarketShares(challenge);
    },

    sortedChallengeResults() {
      return get().marketShares.sort((a, b) => a.year - b.year);
    },

    challenges() {
      return Array(8)
        .fill(0)
        .map((v, i) => i + 1);
    },

    challengesTillCurrent() {
      return get()
        .challenges()
        .filter(c => c <= get().currentChallenge);
    },

    competitors() {
      return Array(10)
        .fill(0)
        .map((v, i) => `P${i + 1}`);
    },

    offer(challenge: number, competitor: string) {
      return get().findOfferWithMarketShare({
        year: challenge,
        competitor,
      });
    },
  };
};

interface IPrivate {}

export const serialize = (s: DeepPartial<IChallenges & IPrivate>) => ({});

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<IChallenges & IPrivate> => s;
