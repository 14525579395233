import { useStore } from '../../store';
import { percent, price, units } from '../layout/formatters';

export const LearningReport = () => {
  const r = useStore(s => s.toLearningReport());

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead>
        <tr>
          <th style={{ width: '70%' }}></th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(f => (
          <tr key={f.id}>
            <th>{f.display}</th>
            <td>{Number.isNaN(r[f.id]) ? 'NaN' : f.format(r[f.id])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const fields = [
  { id: 'learningRate', format: percent, display: 'Learning Rate' },
  {
    id: 'cumulativeProduction',
    format: units,
    display: 'Cumulative Production',
  },
  {
    id: 'economyOfLearning',
    format: percent,
    display: 'Cost Reduction',
  },
];
