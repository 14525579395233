import React from 'react';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { FixedCostReport } from '../product/report.fixed-cost';
import { MultiYearIncomeStatementReport } from '../product/report.income-statement';
import { MultiYearProductionVolumeReport } from '../product/report.production-volume';

export const AnalyzeResults = () => (
  <>
    <SectionTitle id="analyze-results">Analyze Results</SectionTitle>
    <h3>Fixed Costs</h3>
    <Report>
      <FixedCostReport />
    </Report>

    <h3 id="production-volume-summary">Production Volume Summary</h3>
    <Report>
      <MultiYearProductionVolumeReport />
    </Report>

    <h3 id="income-statement">Income Statement</h3>
    <Report>
      <MultiYearIncomeStatementReport />
    </Report>

    <p></p>
    <p>--- end of file ---</p>
  </>
);
