import numeral from 'numeral';
import { useStore } from '../../store';

export const ProductTotalCost: React.FC<{}> = props => {
  const totalCost = useStore(s => s.totalCost());

  return (
    <span style={{ fontWeight: 'bold' }}>
      {numeral(totalCost).format('$ 0,0.00')}
    </span>
  );
};
