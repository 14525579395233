import numeral from 'numeral';
import { useStore } from '../../store';

export const FeatureOptionsSelect: React.FC<{
  featureId: string;
  disabled?: boolean;
}> = props => {
  const availableOptions = useStore(s => s.featureOptions(props.featureId));
  const isFeatureSelectable = useStore(s =>
    s.isFeatureSelectable(props.featureId)
  );
  const assignOptionToFeature = useStore(s => s.assignOptionToFeature);
  const assignedFeatureOption = useStore(s =>
    s.assignedFeatureOption(props.featureId)
  );

  return (
    <select
      disabled={!isFeatureSelectable}
      value={assignedFeatureOption}
      onChange={e =>
        assignOptionToFeature(props.featureId, e.currentTarget.value)
      }
      style={{ width: '90%', padding: '0.2em' }}
    >
      <option value={''}>-- not set --</option>
      {availableOptions.map((o, i) => (
        <option key={i} value={o.optionId} disabled={!o.isAvailable}>
          {o.optionId.padEnd(12, '.')}(
          {numeral(o.extraCostPerUnit).format('$ 0,0.00')})
        </option>
      ))}
    </select>
  );
};
