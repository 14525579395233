import React from 'react';
import { useStore } from '../../store';
import { taskCost } from '../layout/formatters';
import { updateEvery } from '../utils/update-every';

export const TaskListByCategory: React.FC<{ categoryId: string }> = props => {
  const tasks = useStore(s => s.tasksByCategory(props.categoryId));

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead>
        <tr>
          <th style={{ width: '55%' }}>Task</th>
          <th style={{ textAlign: 'right' }}>Cost</th>
          <th style={{ textAlign: 'right' }}>Duration</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tasks.map(t => (
          <Task key={t} taskId={t} />
        ))}
      </tbody>
    </table>
  );
};

const styles = {
  normal: {},

  completed: {
    color: '#ddd',
    textDecoration: 'line-through',
  },
};

const style = (isTaskCompleted: boolean) => () => {
  return isTaskCompleted ? styles.completed : styles.normal;
};

const Task: React.FC<{ taskId: string }> = props => {
  const task = useStore(s => s.taskById(props.taskId));
  const isTaskCompleted = useStore(s => s.isTaskCompleted(props.taskId));

  const s = style(isTaskCompleted);

  return (
    <tr>
      <th style={s()} title={task.toolTip}>
        {task.displayName}
      </th>
      <td style={{ textAlign: 'right', ...s() }}>{taskCost(task.cost)}</td>
      <td style={{ textAlign: 'right', ...s() }}>
        {task.durationInSeconds.toFixed(0)}s
      </td>
      <td style={{ textAlign: 'right' }}>
        <TaskController taskId={props.taskId} />
      </td>
    </tr>
  );
};

export const TaskController: React.FC<{ taskId: string }> = props => {
  const isTaskCompleted = useStore(s => s.isTaskCompleted(props.taskId));
  const isTaskRunning = useStore(s => s.isTaskRunning(props.taskId));

  if (isTaskCompleted)
    return <span style={{ fontWeight: 'bold', color: 'green' }}>DONE!</span>;
  if (isTaskRunning) return <TaskProgress taskId={props.taskId} />;
  return <RunTaskButton taskId={props.taskId} />;
};

export const RunTaskButton: React.FC<{ taskId: string }> = props => {
  const runTask = useStore(s => s.runTask);
  const isLocked = useStore(s => s.isTaskLocked(props.taskId));
  const runningTasks = useStore(s => s.runningTasks);
  const runningTaskLimit = useStore(s => s.runningTaskLimit);

  return (
    <button
      disabled={isLocked}
      onClick={() => {
        if (runningTasks.length >= runningTaskLimit) {
          alert(
            'You reached the max number of parallel task, retry in a few seconds.'
          );
        } else {
          runTask(props.taskId, Date.now());
        }
      }}
    >
      Run
    </button>
  );
};

export const TaskProgress: React.FC<{ taskId: string }> = updateEvery(1000)(
  props => {
    const timeToEndTask = useStore(s =>
      s.timeToEndTask(props.taskId, Date.now())
    );

    return <span>{Math.round(timeToEndTask / 1000)}s</span>;
  }
);
