import React from 'react';
import { Interaction } from '../layout/interaction';
import { Report } from '../layout/report';
import { SectionTitle } from '../layout/section-title';
import { CapacityReport } from '../product/capacity-report';
import { LearningReport } from '../product/report.learning';
import { TaskListByCategory } from '../tasks/task-list-by-category';
import { Term } from '../term';

export const OptimizeProduction = () => (
  <>
    <SectionTitle id="optimize-production">
      Tasks to Optimize your Production Plants
    </SectionTitle>
    <p>
      Invest in your production facilities. Though improving your production
      plant’s efficiency and capacity means an increment in your fixed annual
      costs, in the long run it can help you save and make more money.
    </p>

    <p>Remember, your market share in $ is a function of volume and price.</p>

    <h3>Improve Efficiency</h3>

    <Interaction>
      <TaskListByCategory categoryId="improve-efficiency" />
    </Interaction>

    <h3>Expand Capacity</h3>

    <Interaction>
      <TaskListByCategory categoryId="expand-capacity" />
    </Interaction>

    <h3 id="economy-of-learning">Economies of Learning</h3>
    <p>
      This report helps you keep track of how much you’ve invested in becoming
      more efficient and leverage Economies of Learning.
    </p>
    <Report>
      <LearningReport />
    </Report>

    <h3 id="production-capacity">Production Capacity Status</h3>
    <p>
      This report helps you keep track of how much you’ve invested in expanding
      your Plant’s capacity.
    </p>
    <Report>
      <CapacityReport />
    </Report>
  </>
);
