import numeral from 'numeral';

export const price = (v: number) => numeral(v).format('$ 0,0');
export const priceInK = (v: number) => numeral(v).format('$ 0,0.0 a');
export const taskCost = (v: number) => numeral(v).format('$ 0,0');
export const fixedCost = (v: number) => numeral(v).format('$ 0,0.00 a');
export const revenues = (v: number) => numeral(v).format('$ 0,0.00 a');
export const balance = (v: number) => numeral(v).format('$ 0,0.00 a');
export const percent = (v: number) => numeral(v).format('0 %');
export const units = (v: number) => numeral(v).format('0,0');
export const level = (v: number) => numeral(v).format('0');
export const param = (v: number) => numeral(v).format('0.00');
