import create, { GetState, SetState, UseBoundStore } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import * as Game from './factories/game';
import * as Product from './factories/product';
import * as Logger from './factories/logger';
import * as Challenges from './factories/challenges';
import * as Player from './factories/player';
import * as Tasks from './factories/tasks';
import * as Market from './factories/market';

type StoreType = ReturnType<typeof createRootSlice>;

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Game.create(set, get),
  ...Product.create(set, get),
  ...Logger.create(set, get),
  ...Challenges.create(set, get),
  ...Player.create(set, get),
  ...Tasks.create(set, get),
  ...Market.create(set, get),
});

export const useStore: UseBoundStore<StoreType> &
  StoreApiWithPersist<StoreType> = create(
  persist(createRootSlice, {
    name: 'high-voltage',

    onRehydrateStorage: state => {
      console.log('»» store hydration started');
      return (state, error) => {
        if (error) {
          console.error('an error happened during hydration', error);
        } else {
          state._run();
          console.log('»» store hydration finished');
        }
      };
    },

    serialize: storage => {
      return JSON.stringify({
        state: {
          ...Game.serialize(storage.state),
          ...Product.serialize(storage.state),
          ...Logger.serialize(storage.state),
        },
        version: storage.version,
      });
    },

    deserialize: str => {
      const obj = JSON.parse(str);
      const store = {
        state: {
          ...Game.deserialize(obj.state),
          ...Product.deserialize(obj.state),
          ...Logger.deserialize(obj.state),
        },
        version: obj.version,
      };

      console.log('base', obj);
      console.log('dese', store);

      return store;
    },
  })
);
