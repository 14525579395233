import { useStore } from '../../store';
import { percent, price } from '../layout/formatters';

export const VariableCostReport = () => {
  const r = useStore(s => s.toVariableCostReport());

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <thead>
        <tr>
          <th style={{ width: '70%' }}></th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((f, i) => (
          <tr key={i}>
            <th>{f.display}</th>
            <td>{f.format(r[f.id])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const fields = [
  { id: 'baseCostPerUnit', format: price, display: 'Base Cost per Unit' },
  {
    id: 'manufacturingEfficiency',
    format: percent,
    display: 'Manufacturing Efficiency',
  },
  {
    id: 'baseCostAfterImprovement',
    format: price,
    display: 'Cost After Manufacturing Efficiency',
  },
  { id: 'extraCostForDesign', format: price, display: 'Extra Cost for Design' },
  {
    id: 'extraCostForEngineering',
    format: price,
    display: 'Extra Cost for Engineering',
  },
  {
    id: 'extraCostForPerformance',
    format: price,
    display: 'Extra Cost for Performance',
  },
  {
    id: 'extraCostForRange',
    format: price,
    display: 'Extra Cost for Range',
  },
  { id: 'extraCostForSafety', format: price, display: 'Extra Cost for Safety' },
  {
    id: 'baseCostAfterFeatureCost',
    format: price,
    display: 'Base Cost After Feature Cost',
  },
  {
    id: 'economyOfScale',
    format: percent,
    display: 'Economies of Scales',
  },
  {
    id: 'totalCostAfterEconomyOfScale',
    format: price,
    display: 'Cost After Economies of Scales',
  },
  {
    id: 'economyOfLearning',
    format: v => v,
    display: 'Economies of Learning',
  },
  {
    id: 'totalCostAfterEconomyOfLearning',
    format: price,
    display: 'Cost After Economies of Learning',
  },
  {
    id: 'totalCostAfterEconomyOfLearning',
    format: price,
    display: 'Final Cost per Unit',
  },
];
