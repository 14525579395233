import { FeatureIds } from '../concepts/product';
import {
  acquireDesigner,
  expandCapacity,
  extractTask,
  improve,
  improveBrandAwareness,
  improveEfficiency,
  investigateMarket,
} from '../concepts/tasks';

const investigationTasks = (levels: number[]) => {
  return FeatureIds.map((f, j) => {
    return levels
      .map((l, i) => ({
        [`investigate-on-${f}_${i + 1}`]: investigateMarket({
          featureId: f,
          noiseLevel: l,
        })({
          cost: 2_500,
          durationInSeconds: 20,
          unlocks: [`investigate-on-${f}_${i + 2}`],
          sequenceOrder: j * 100 + i,
          isLocked: i > 0,
        })(`Investigate on ${f} (L. ${i + 1})`),
      }))
      .reduce((map, t) => ({ ...map, ...t }), {});
  }).reduce((map, t) => ({ ...map, ...t }), {});
};

export const tasks = extractTask({
  /** INVESTIGATE MARKET */

  ...investigationTasks([0.5, 0.2, 0.05]),
  /** ACQUIRE KNOW-HOW (DESIGN) */

  acquire_designer_super: acquireDesigner({ categories: ['super', 'great'] })({
    cost: 50_000,
    durationInSeconds: 60,
    sequenceOrder: 0,
  })('Form a SUPER Design Team'),

  acquire_designer_great: acquireDesigner({ categories: ['great', 'average'] })(
    { cost: 40_000, durationInSeconds: 20, sequenceOrder: 1 }
  )('Form a GREAT Design Team'),

  acquire_designer_average: acquireDesigner({ categories: ['average'] })({
    cost: 10_000,
    durationInSeconds: 10,
    sequenceOrder: 2,
  })('Form an AVERAGE Design Team'),

  acquire_designer_cheap: acquireDesigner({ categories: ['cheap'] })({
    cost: 5_000,
    durationInSeconds: 5,
    sequenceOrder: 3,
  })('Form a CHEAP Design Team'),

  /** IMPROVE TECHNOLOGY */

  improve_size: improve({
    featureId: 'size',
    levelIncrement: 5,
    costIncrementRate: 1,
    durationIncrementRate: 0.3,
  })({ cost: 200, durationInSeconds: 5, isReusable: true })(
    'Improve Size with 5 Levels'
  ),
  improve_performance: improve({
    featureId: 'performance',
    levelIncrement: 5,
    costIncrementRate: 1,
    durationIncrementRate: 0.3,
  })({ cost: 200, durationInSeconds: 5, isReusable: true })(
    'Improve Performance with 5 Levels'
  ),
  improve_range: improve({
    featureId: 'range',
    levelIncrement: 5,
    costIncrementRate: 1,
    durationIncrementRate: 0.3,
  })({ cost: 200, durationInSeconds: 5, isReusable: true })(
    'Improve Range with 5 Levels'
  ),
  improve_safety: improve({
    featureId: 'safety',
    levelIncrement: 5,
    costIncrementRate: 1,
    durationIncrementRate: 0.3,
  })({ cost: 200, durationInSeconds: 5, isReusable: true })(
    'Improve Safety with 5 Levels'
  ),

  /** IMPROVE EFFICIENCY */

  efficiency_level_1: improveEfficiency({
    fixedAnnualCostIncrement: 20_000,
    learningRate: 0.025,
    manufacturingEfficiency: 0.075,
  })({ cost: 10000, durationInSeconds: 120, unlocks: ['efficiency_level_2'] })(
    'Improve Efficiency L. 1',
    '+2.5% Learning Rate, +7.5% Man. Eff., +20k Fixed Costs'
  ),
  efficiency_level_2: improveEfficiency({
    fixedAnnualCostIncrement: 18000,
    learningRate: 0.02,
    manufacturingEfficiency: 0.06,
  })({
    cost: 10000,
    durationInSeconds: 120,
    isLocked: true,
    unlocks: ['efficiency_level_3'],
  })(
    'Improve Efficiency L. 2',
    '+2.0% Learning Rate, +6.0% Man. Eff., +18k Fixed Costs'
  ),
  efficiency_level_3: improveEfficiency({
    fixedAnnualCostIncrement: 16000,
    learningRate: 0.015,
    manufacturingEfficiency: 0.05,
  })({
    cost: 10000,
    durationInSeconds: 120,
    isLocked: true,
    unlocks: ['efficiency_level_4'],
  })(
    'Improve Efficiency L. 3',
    '+1.5% Learning Rate, +5.0% Man. Eff., +16k Fixed Costs'
  ),
  efficiency_level_4: improveEfficiency({
    fixedAnnualCostIncrement: 14000,
    learningRate: 0.01,
    manufacturingEfficiency: 0.04,
  })({
    cost: 10000,
    durationInSeconds: 120,
    isLocked: true,
    unlocks: ['efficiency_level_5'],
  })(
    'Improve Efficiency L. 4',
    '+1.0% Learning Rate, +4.0% Man. Eff., +14k Fixed Costs'
  ),
  efficiency_level_5: improveEfficiency({
    fixedAnnualCostIncrement: 12000,
    learningRate: 0.005,
    manufacturingEfficiency: 0.035,
  })({ cost: 10000, durationInSeconds: 120, isLocked: true })(
    'Improve Efficiency L. 5',
    '+0.5% Learning Rate, +3.5% Man. Eff., +12k Fixed Costs'
  ),

  /** EXPAND CAPACITY */

  capacity_level_1: expandCapacity({
    capacityIncrease: 0.5,
    fixedAnnualCostIncrement: 250_000,
    manufacturingEfficiency: 0.15,
  })({ cost: 10000, durationInSeconds: 60, unlocks: ['capacity_level_2'] })(
    'Expand Capacity L. 1',
    '+50.0% Capacity Increase, +15.0% Econ. of scales, +250k Fixed Costs'
  ),
  capacity_level_2: expandCapacity({
    capacityIncrease: 0.5,
    fixedAnnualCostIncrement: 225_000,
    manufacturingEfficiency: 0.1,
  })({
    cost: 10000,
    durationInSeconds: 60,
    isLocked: true,
    unlocks: ['capacity_level_3'],
  })(
    'Expand Capacity L. 2',
    '+50.0% Capacity Increase, +10.0% Econ. of scales, +225k Fixed Costs'
  ),
  capacity_level_3: expandCapacity({
    capacityIncrease: 0.5,
    fixedAnnualCostIncrement: 200_000,
    manufacturingEfficiency: 0.075,
  })({
    cost: 10000,
    durationInSeconds: 60,
    isLocked: true,
    unlocks: ['capacity_level_4'],
  })(
    'Expand Capacity L. 3',
    '+50.0% Capacity Increase, +7.5% Econ. of scales, +200k Fixed Costs'
  ),
  capacity_level_4: expandCapacity({
    capacityIncrease: 0.5,
    fixedAnnualCostIncrement: 175_000,
    manufacturingEfficiency: 0.06,
  })({
    cost: 10000,
    durationInSeconds: 60,
    isLocked: true,
    unlocks: ['capacity_level_5'],
  })(
    'Expand Capacity L. 4',
    '+50.0% Capacity Increase, +6.0% Econ. of scales, +175k Fixed Costs'
  ),
  capacity_level_5: expandCapacity({
    capacityIncrease: 0.5,
    fixedAnnualCostIncrement: 150_000,
    manufacturingEfficiency: 0.04,
  })({ cost: 10000, durationInSeconds: 60, isLocked: true })(
    'Expand Capacity L. 5',
    '+50.0% Capacity Increase, +4.0% Econ. of scales, +150k Fixed Costs'
  ),

  /** BRAND AWARENESS */
  awareness_level_1: improveBrandAwareness({ brandAwarenessLevel: 0.2 })({
    cost: 20000,
    durationInSeconds: 300,
    unlocks: ['awareness_level_2'],
  })('Brand Awareness L. 1', '+20.0% Brand Awareness Index'),
  awareness_level_2: improveBrandAwareness({ brandAwarenessLevel: 0.2 })({
    cost: 20000,
    durationInSeconds: 300,
    isLocked: true,
    unlocks: ['awareness_level_3'],
  })('Brand Awareness L. 2', '+20.0% Brand Awareness Index'),
  awareness_level_3: improveBrandAwareness({ brandAwarenessLevel: 0.2 })({
    cost: 20000,
    durationInSeconds: 300,
    isLocked: true,
    unlocks: ['awareness_level_4'],
  })('Brand Awareness L. 3', '+20.0% Brand Awareness Index'),
  awareness_level_4: improveBrandAwareness({ brandAwarenessLevel: 0.2 })({
    cost: 20000,
    durationInSeconds: 300,
    isLocked: true,
    unlocks: ['awareness_level_5'],
  })('Brand Awareness L. 4', '+20.0% Brand Awareness Index'),
  awareness_level_5: improveBrandAwareness({ brandAwarenessLevel: 0.2 })({
    cost: 20000,
    durationInSeconds: 300,
    isLocked: true,
  })('Brand Awareness L. 5', '+20.0% Brand Awareness Index'),
});
