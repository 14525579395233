export const Param: React.FC<{
  children: React.ReactNode | React.ReactNode[];
}> = props => {
  return (
    <span
      style={{
        fontWeight: 'bold',
        textDecoration: 'underline',
        textUnderlineOffset: '0.1em',
      }}
    >
      {props.children}
    </span>
  );
};
