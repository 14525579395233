import { useStore } from '../../store';
import { units } from '../layout/formatters';

export const SegmentPotentialDemand: React.FC<{
  segmentId: string;
  year: number;
}> = props => {
  const potentialDemand = useStore(s =>
    s.segmentPotentialDemand(props.segmentId, props.year)
  );

  return <span>{units(potentialDemand)}</span>;
};
