import { IGame, PlayOptions } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<ILogger & IPrivate, IGame> = (set, get) => {
  return {
    log: (msg: string) => {
      console.log(
        `»» [${formatInSeconds(get().timeElapsed(Date.now()))}] ${msg}`
      );
    },
    error: (reason: string) => {
      console.log(
        `»! [${formatInSeconds(get().timeElapsed(Date.now()))}] ${reason}`
      );
    },
  };
};

interface IPrivate {}

export const serialize = (s: DeepPartial<ILogger & IPrivate>) => ({});

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<ILogger & IPrivate> => s;

function formatInSeconds(time: number) {
  return `${(time / 1000).toFixed(1)}s`.padStart(6, ' ');
}
