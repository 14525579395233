export interface IProduct {
  /** feature set */
  featureSet: Record<string, string>;
  featuresLevel: Record<string, number>;

  featureOptions(featureId: string): FeatureOption[];
  featureAvailableOptions(featureId: string): FeatureOption[];
  assignedFeatureOption(featureId: string): string;
  unsetFeatures(): string[];
  featuresIds(): string[];
  featureLevel(featureId: string): number;
  isFeatureSelectable(featureId: string): boolean;

  assignOptionToFeature(featureId: string, optionId: string): void;
  unlockFeatureOption(featureId: string, optionId: string): void;
  increaseFeatureLevel(featureId: string, increment: number): void;

  /** variable economics */
  baseCostPerUnit: number;
  price: number;

  baseCostAfterImprovement(): number;
  featureExtraCost(featureId: string): number;
  featureExtraFixedCost(featureId: string): number;
  totalFeatureExtraCost(): number;
  totalCostBeforeEconomyOfScale(): number;
  totalCostAfterEconomyOfScale(): number;
  totalCostBeforeEconomyOfLearning(): number;
  totalCostAfterEconomyOfLearning(): number;
  totalCost(): number;
  minimumPrice(): number;
  effectivePrice(): number;
  isPriceUnderCost(): boolean;

  toVariableCostReport(): VariableCostsReport;

  assignPrice(price: number): void;

  /** fixed economics */
  baseFixedCosts: number;
  extraCostForCapacity: number;
  extraCostForDesign: number;
  extraCostForEngineering: number;
  extraCostForPerformance: number;
  extraCostForRange: number;
  extraCostForSafety: number;
  costForRunningTasks: number;
  extraCostForEfficiencyImprovement: number;

  incomeStatementsByYear: IncomeStatement[];

  totalFixedCosts(): number;
  incomeStatementByYear(year: number): IncomeStatement;
  toFixedCostReport(): FixedCostsReport;

  /** learning effect */
  learningRate: number;
  economyOfLearning(): number;
  toLearningReport(): LearningReport;

  /** launch */
  isProductLaunched: boolean;
  canProductBeLaunched(): boolean;

  launchProduct(): void;

  /** production */
  standardCapacity: number;
  capacityImprovement: number;
  producedUnitsByYear: number[];
  manufacturingEfficiency: number;
  scaleEfficiency: number;
  productionVolumeReportsByYear: ProductionVolumeReport[];

  actualCapacity(): number;
  economyOfScale(): number;
  cumulativeProduction(): number;
  toProductionCapacityReport(): ProductionCapacityReport;
  productionVolumeReportByYear(year: number): ProductionVolumeReport;

  improveScaleEfficiency(ratio: number): void;
  improveManufacturingEfficiency(ratio: number): void;
  incrementCapacity(ratio: number): void;
  produceAndSold(units: number, price: number, year: number): void;

  /** brand awareness */
  brandAwareness: number;

  /** competition (move directly to IMarket?) */
  offerSubmitted: boolean;

  submitOffer(challenge: number): void;
  updateChallengeResults(challenge: number): void;
}

export type FeatureOption = {
  featureId: string;
  optionId: string;
  isAvailable: boolean;
  extraCostPerUnit: number;
  extraFixedCost: number;
};

export const FeatureIds = ['design', 'size', 'performance', 'range', 'safety'];

export const FeatureOptions = {
  design: ['cheap', 'average', 'great', 'super'],
  size: ['compact', 'sedan', 'suv', 'pick-up'],
  performance: ['slow', 'average', 'good', 'sport'],
  range: ['city', 'good', 'long', 'top'],
  safety: ['adequate', 'average', 'good', 'outstanding'],
};

export type ProductionCapacityReport = {
  standardCapacity: number;
  capacityImprovement: number;
  actualCapacity: number;
};

export type ProductionVolumeReport = {
  standardCapacity: number;
  capacityImprovement: number;
  actualCapacity: number;
  demand: number;
  productionVolume: number;
};

export type IncomeStatement = {
  baseFixedCosts: number;
  extraCostForCapacity: number;
  extraCostForDesign: number;
  extraCostForEngineering: number;
  extraCostForPerformance: number;
  extraCostForRange: number;
  extraCostForSafety: number;
  costForRunningTasks: number;
  extraCostForEfficiencyImprovement: number;

  baseCostPerUnit: number;
  baseCostAfterImprovement: number;
  baseCostAfterFeatureCost: number;
  totalCostAfterEconomyOfScale: number;
  totalCostAfterEconomyOfLearning: number;

  totalFixedCosts: number;
  totalVariableCosts: number;
  totalCosts: number;
  revenue: number;
  operatingIncome: number;
};

export type FixedCostsReport = {
  baseFixedCosts: number;
  extraCostForCapacity: number;
  extraCostForDesign: number;
  extraCostForEngineering: number;
  extraCostForPerformance: number;
  extraCostForRange: number;
  extraCostForSafety: number;
  costForRunningTasks: number;
  extraCostForEfficiencyImprovement: number;

  totalFixedCosts: number;
};

export type VariableCostsReport = {
  baseCostPerUnit: number;
  baseCostAfterImprovement: number;
  extraCostForDesign: number;
  extraCostForEngineering: number;
  extraCostForPerformance: number;
  extraCostForRange: number;
  extraCostForSafety: number;

  baseCostAfterFeatureCost: number;
  totalCostAfterEconomyOfScale: number;
  totalCostAfterEconomyOfLearning: number;

  manufacturingEfficiency: number;
  economyOfScale: number;
  economyOfLearning: number;
};

export type LearningReport = {
  learningRate: number;
  cumulativeProduction: number;
  economyOfLearning: number;
  costPerUnitAssumingFullCapacity: number;
  costStavingDueToLearning: number;
};
