import { useStore } from '../../store';
import { fixedCost } from '../layout/formatters';

export const FeatureExtraFixedCost: React.FC<{ featureId: string }> = props => {
  const featureExtraCost = useStore(s =>
    s.featureExtraFixedCost(props.featureId)
  );

  return <span>{fixedCost(featureExtraCost)}</span>;
};
