import produce from 'immer';
import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import {
  IMarket,
  MarketShareSegments,
  MarketShareSegmentsMap,
  MarketShareUnits,
  Offer,
  OfferHeader,
  OfferWithMarketSegments,
  OfferWithMarketShare,
  OfferWithMarkSegmentsMarketShare,
  PerceivedUtility,
} from '../concepts/market';
import { IPlayer } from '../concepts/player';
import { FeatureIds, FeatureOptions, IProduct } from '../concepts/product';
import { DeepPartial } from '../utils/deep-partial';
import { StoreSlice } from '../utils/store-slice';

export const create: StoreSlice<
  IMarket & IPrivate,
  IGame & ILogger & IProduct & IPlayer
> = (set, get) => {
  return {
    /** information noise */

    noiseRatios: {},
    setInformationNoise(featureId: string, level: number) {
      set(
        produce((s: IMarket) => {
          s.noiseRatios[featureId] = level;
        })
      );
    },

    /** preference */

    featurePreference(segmentId, featureId, optionId) {
      const noise = get().noiseRatios[featureId] || 1;
      const index = featureOptionIndex(featureId)(optionId);
      return preferencesBySegment[segmentId][featureId].map(
        p => (p + noise) / (1 + 4 * noise)
      )[index];
    },

    segmentPerceivedUtility(segmentId, offer) {
      const ff = utilityFromFeatureSet(preferencesBySegment[segmentId])(
        offer.featureSet
      );
      const pp = segments[segmentId].pricePositioningEffect(offer.price);
      const ph = segments[segmentId].priceHarmfulEffect(offer.price);

      const final = Math.max(0, ff * pp - ph);

      return {
        final,
        fromFeatureSet: ff,
        pricePositioningEffect: pp,
        priceHarmfulEffect: ph,
      };
    },

    /** demand */
    potentialDemands: {
      luxury: [0, 2400, 19451, 18296, 31339, 68043, 75071, 89436, 69655],
      mass: [0, 46168, 65064, 85498, 69659, 79052, 110507, 266563, 254179],
    },

    satisfiedDemands: {
      luxury: [],
      mass: [],
    },

    segmentUncommittedDemandUntil(segmentId, year) {
      return 0;
    },

    segmentPotentialDemand(segmentId, year) {
      return (
        get().potentialDemands[segmentId][year] +
        get().segmentUncommittedDemandUntil(segmentId, year - 1)
      );
    },

    satisfySegmentDemand(segmentId, year, unitsSold) {
      get().log(
        `segment demand satisfied: ${segmentId} on ${year} for ${unitsSold}`
      );

      set(
        produce((s: IMarket) => {
          s.satisfiedDemands[segmentId][year] = Math.floor(unitsSold);
        })
      );
    },

    /** competition */
    offers: [],
    marketShares: [],

    hadOfferBeenIssuedBy(header) {
      return !!get().offers.find(isSameOfferOf(header));
    },

    findOffer(header) {
      return get().offers.find(isSameOfferOf(header));
    },

    findOfferWithMarketShare(header) {
      return get().marketShares.find(isSameOfferOf(header));
    },

    issueOffer(offer) {
      if (get().hadOfferBeenIssuedBy(offer)) {
        throw new Error(
          `Offer has been already issued by ${offer.competitor} at ${offer.year}`
        );
      }

      set(
        produce((s: IMarket) => {
          s.offers.push(offer);
        })
      );
    },

    evaluateMarketShares(year: number) {
      const segments: ISegment[] = [
        segment(get())('luxury'),
        segment(get())('mass'),
      ];

      const marketShares = get()
        .offers.filter(isIssueOnYear(year))
        .map(withMarketSegments(segments))
        .map(withSegmentsMarketShare())
        .map(withSegmentMap())
        .map(withUnits())
        .map(withTotals());

      marketShares
        .filter(ms => ms.competitor === get().playerId)
        .forEach(ms => {
          get().produceAndSold(ms.unitsSold, ms.price, year);
        });

      set(
        produce((s: IMarket) => {
          s.marketShares = s.marketShares.concat(marketShares);
        })
      );
    },
  };
};

interface IPrivate {}

export const serialize = (s: DeepPartial<IMarket & IPrivate>) => ({});

export const deserialize = (
  s: ReturnType<typeof serialize>
): DeepPartial<IMarket & IPrivate> => s;

const segment =
  (markets: IMarket) =>
  (segmentId: string): ISegment => {
    return {
      id: segmentId,
      potentialDemand: year => markets.segmentPotentialDemand(segmentId, year),
      perceivedUtility: offer =>
        markets.segmentPerceivedUtility(segmentId, offer),
    };
  };

const withMarketSegments =
  (segments: ISegment[]) =>
  (offer: Offer): OfferWithMarketSegments => ({
    ...offer,
    segments: segments.map(segment => ({
      id: segment.id,
      potentialDemand: segment.potentialDemand(offer.year),
      perceivedUtility: segment.perceivedUtility(offer),
    })),
  });

const withSegmentsMarketShare =
  () =>
  (
    offer: OfferWithMarketSegments,
    i: number,
    offers: OfferWithMarketSegments[]
  ): OfferWithMarkSegmentsMarketShare => ({
    ...offer,
    segments: offer.segments
      .map((segment, j) => ({
        ...segment,
        marketShare:
          segment.perceivedUtility.final > 0
            ? segment.perceivedUtility.final /
                offers.reduce(
                  (a, o) => a + (o.segments[j].perceivedUtility.final || 0),
                  0
                ) +
              offer.brandAwarenessEffect
            : 0,
      }))
      .map(segment => ({
        ...segment,
        unitsSold: Math.floor(segment.marketShare * segment.potentialDemand),
      })),
  });

const withSegmentMap =
  () =>
  (
    offer: OfferWithMarkSegmentsMarketShare
  ): Offer & MarketShareSegmentsMap => ({
    ...offer,
    segmentsById: offer.segments.reduce(
      (map, s) => ({ ...map, [s.id]: s }),
      {}
    ),
  });

const withUnits =
  () =>
  (
    offer: Offer & MarketShareSegments & MarketShareSegmentsMap
  ): Offer &
    MarketShareSegments &
    MarketShareUnits &
    MarketShareSegmentsMap => ({
    ...offer,
    potentialDemand: offer.segments.reduce((a, s) => a + s.potentialDemand, 0),
    unitsSold: offer.segments.reduce((a, s) => a + s.unitsSold, 0),
  });

const withTotals =
  () =>
  (
    offer: Offer &
      MarketShareSegments &
      MarketShareSegmentsMap &
      MarketShareUnits
  ): OfferWithMarketShare => ({
    ...offer,
    marketShare: offer.unitsSold / offer.potentialDemand,
  });

interface ISegment {
  id: string;
  potentialDemand(year: number): number;
  perceivedUtility(offer: Offer): PerceivedUtility;
}

const isSameOfferOf = (a: OfferHeader) => (b: OfferHeader) => {
  return a.competitor === b.competitor && a.year === b.year;
};

const isIssueOnYear = (year: number) => (b: { year: number }) => {
  return b.year === year;
};

const utilityFromFeatureSet =
  (preference: Record<string, number[]>) =>
  (featureSet: Record<string, string>): number => {
    const preferences = FeatureIds.map(
      f => preference[f][featureOptionIndex(f)(featureSet[f])]
    );

    return (
      preferences.map(p => p || 0).reduce((sum, p) => sum + p, 0) /
      preferences.length
    );
  };

const priceHarmfulEffect =
  (maxPrice: number) =>
  (price: number): number => {
    return price / maxPrice;
  };

const pricePositioningEffect =
  (minPrice: number) =>
  (price: number): number => {
    if (price >= minPrice) return 1;
    if (price < minPrice * 0.2) return 0;
    return (price - minPrice * 0.2) / (minPrice * 0.8);
  };

const featureOptionIndex = (featureId: string) => (optionId: string) => {
  return FeatureOptions[featureId].indexOf(optionId);
};

const preferencesBySegment: Record<string, Record<string, number[]>> = {
  luxury: {
    design: [0, 0.04, 0.12, 0.85],
    size: [0.05, 0.71, 0.23, 0.0],
    performance: [0, 0.03, 0.68, 0.28],
    range: [0.0, 0.84, 0.06, 0.1],
    safety: [0.0, 0.0, 0.17, 0.83],
  },

  mass: {
    design: [0.44, 0.56, 0.0, 0.0],
    size: [0.64, 0.35, 0.0, 0.0],
    performance: [0.67, 0.2, 0.13, 0.0],
    range: [0.6, 0.01, 0.26, 0.13],
    safety: [0.35, 0.52, 0.13, 0.0],
  },
};

const segments = {
  luxury: {
    priceHarmfulEffect: priceHarmfulEffect(150000),
    pricePositioningEffect: pricePositioningEffect(50000),
  },

  mass: {
    priceHarmfulEffect: priceHarmfulEffect(60000),
    pricePositioningEffect: pricePositioningEffect(5000),
  },
};
