import React from 'react';
import { useStore } from '../../store';
import { ListOfWords } from '../layout/list-of-words';
import { Term } from '../term';

export const LaunchProductButton: React.FC<{}> = props => {
  const canProductBeLaunched = useStore(s => s.canProductBeLaunched());
  const launchProduct = useStore(s => s.launchProduct);
  const isProductLaunched = useStore(s => s.isProductLaunched);

  if (isProductLaunched)
    return <span>The product has been launched already</span>;

  return (
    <>
      {!canProductBeLaunched && <ImpossibleToLaunchMessage />}
      <button
        disabled={!canProductBeLaunched}
        onClick={() => {
          if (confirm('Are you sure you want to launch your product?')) {
            launchProduct();
          }
        }}
      >
        Launch now!
      </button>
    </>
  );
};

const ImpossibleToLaunchMessage = () => {
  const unsetFeatures = useStore(s => s.unsetFeatures());

  return (
    <p>
      Mmm... something is missing here...
      <br />
      The product cannot be launched right now.
      <br />
      Your product is missing the following features:{' '}
      <ListOfWords>
        {unsetFeatures.map(id => (
          <Term key={id} lemma={id} />
        ))}
      </ListOfWords>
    </p>
  );
};
