export const Term: React.FC<{ lemma: string }> = props => {
  return (
    <em
      style={{
        // textDecoration: 'underline',
        // textUnderlineOffset: '2px',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        background: 'yellow',
        color: 'black',
        padding: '0.1em 0em',
        cursor: 'help',
      }}
    >
      {props.lemma}
    </em>
  );
};

type Lemmas = 'product' | 'feature' | 'feature-option' | 'launch';
