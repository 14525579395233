import { DateTime } from 'luxon';
import { ProgressBar } from 'react-bootstrap';
import { useStore } from '../../store';
import { updateEvery } from '../utils/update-every';

export const SprintInfo = updateEvery(1000)(() => {
  const currentSprint = useStore(s => s.currentSprint);
  const currentSprintEndTime = useStore(s => s.currentSprintEndTime);
  const currentSprintStartTime = useStore(s => s.currentSprintStartTime);
  const sprintProgress = useStore(s => s.sprintProgress);
  const isStarted = useStore(s => s.isStarted);
  const isStopped = useStore(s => s.isStopped);

  if (!isStarted || isStopped) return null;

  if (currentSprintEndTime < Date.now())
    return (
      <span style={{ color: 'red', fontWeight: 'bold' }}>
        Challenge in progress...
      </span>
    );

  return (
    <div>
      <div style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
        Current Sprint/Year: {(currentSprint + 1).toFixed(0).padStart(2, '0')}
      </div>
      <div style={{ fontSize: '0.85em' }}>
        it began{' '}
        {DateTime.fromMillis(currentSprintStartTime)
          .setLocale('en')
          .toRelative()}{' '}
        <br />
        and it will end{' '}
        {DateTime.fromMillis(currentSprintEndTime).setLocale('en').toRelative()}
      </div>

      <ProgressBar now={sprintProgress(Date.now()) * 100} />
    </div>
  );
});
