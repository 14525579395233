import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { DefineYourProduct } from './comps/environments/define-your-product';
import { CompeteOnTheMarket } from './comps/environments/compete-on-the-market';
import { InvestigateMarket } from './comps/environments/investigate-market';
import { AcquireKnowHow } from './comps/environments/acquire-know-how';
import { OptimizeProduction } from './comps/environments/optimize-production';
import { BoostBrandAwareness } from './comps/environments/boost-brand-awareness';
import { AnalyzeResults } from './comps/environments/analyze-results';
import { TimeElapsed } from './comps/clock/time-elapsed';
import { Introduction } from './comps/environments/introduction';
import { StartButton } from './comps/game/start-button';
import { GameRules } from './comps/environments/game-rules';
import { SprintInfo } from './comps/clock/sprint-info';
import { Initializer } from './comps/game/initializer';
import { GameStatus } from './comps/game/game-status';
import { WindowOfOpportunityCount } from './comps/clock/window-opportunity-count';
import { IndexOfSections } from './index-of-sections';
import { IndexOfReports } from './index-of-reports';
import { useStore } from './store';

export const App = () => {
  return (
    <Initializer>
      <Container>
        <Row>
          <Col xs={3} style={{ position: 'sticky' }}>
            <Summary />
          </Col>
          <Col xs={9}>
            <Main />
          </Col>
          <Col xs={1}></Col>
        </Row>
      </Container>
    </Initializer>
  );
};

const Summary = () => {
  const runningTasks = useStore(s => s.runningTasks);

  return (
    <div className="sticky-top pt-4">
      <div className="mb-4">
        <IndexOfSections />
      </div>

      <hr />

      <div className="mb-4">
        <IndexOfReports />
      </div>

      <hr />

      <div>
        # of windows of opportunity: <WindowOfOpportunityCount />
      </div>
      <div>
        <GameStatus />
      </div>
      <div>
        <TimeElapsed />
      </div>
      <div>
        <SprintInfo />
      </div>

      <div style={{ marginTop: '2em' }}>
        <h5>Running Tasks</h5>

        {runningTasks.length > 0 ? (
          <ol>
            {[...runningTasks]
              .sort((a, b) => a.endTime - b.endTime)
              .map((t, i) => (
                <li key={i}>{t.taskId}</li>
              ))}
          </ol>
        ) : (
          <span>No task is running...</span>
        )}
      </div>

      <StartButton />
    </div>
  );
};

const Main = () => (
  <Container>
    <Row>
      <Col xs={9}>
        <Introduction />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <GameRules />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <DefineYourProduct />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <CompeteOnTheMarket />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <InvestigateMarket />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <AcquireKnowHow />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <OptimizeProduction />
      </Col>
    </Row>

    <Row>
      <Col xs={9}>
        <BoostBrandAwareness />
      </Col>
    </Row>

    <Row>
      <Col xs={12}>
        <AnalyzeResults />
      </Col>
    </Row>
  </Container>
);
