import { useStore } from '../../store';

export const WindowOfOpportunityDuration = () => {
  const windowOfOpportunityDurationInSeconds = useStore(s =>
    s.windowOfOpportunityDurationInSeconds()
  );

  return (
    <span>
      {(windowOfOpportunityDurationInSeconds / 60).toFixed(1)} minutes
    </span>
  );
};
