import { Param } from '../layout/param';
import { SectionTitle } from '../layout/section-title';
import { Story } from '../layout/story';

export const Introduction = () => {
  return (
    <>
      <SectionTitle id="background">The Background</SectionTitle>

      <Story>
        <p>
          It's <Param>2011</Param>, your company has decided to take advantage
          of a promising market and launch an electric vehicle (EV) on the US
          market. The idea is to go for an all-electric vehicle, not a plug-in
          hybrid electric vehicle.
        </p>

        <p>
          But competition is fierce. The mounting concerns about climate change
          among the population, and the numerous regulatory incentives that push
          for the adoption of hybrid and electric cars have brought a lot of
          companies to the same conclusion: it’s time to take a ride on the
          green side!
        </p>

        <p>
          You have 8 years in total to design, engineer, produce and launch the
          best car on the market. Ultimately, you need to get the highest
          possible profitability and ROA - which will depend on your market
          share and your cost structure.
        </p>

        <p>
          Each year (5 minutes in real time) you can perform a series of Tasks
          (up to 5 in parallel) that will cost you time and money. The Tasks you
          perform have an influence both on the 5 Features available to
          configure your product and the quantity of cars you can produce.
        </p>

        <p>
          You must be competitive. Pick and build the size of your model like
          compact, sedan, SUV or pick up. Test your cars' safety to see if they
          comply with the required standards. Set their performance level and
          range to check whether your ride has the right kind of muscle under
          the hood. As for the design, choose wisely and fast.
        </p>

        <p>
          Define your own strategy, decide what Tasks you wish to run first.
          Mind though, some depend upon others.{' '}
        </p>

        <p>
          Towards the end of each year (approximately 1 minute), you can observe
          the consequence of your actions on your Income as well as the state of
          the market.{' '}
        </p>

        <p>When you’re ready, set a price and go to the market!</p>
        <p>
          So, without further ado..let’s get it started! Design cars, build
          them, expand your plant, expand your knowledge, research new
          technology, make yourself known, keep an eye on competitors, and
          conquer the market!
        </p>

        <p>Basically, make tons of money and stay on top!</p>
      </Story>
    </>
  );
};
