import { useStore } from '../../store';
import { fixedCost, percent, price } from '../layout/formatters';

export const FixedCostReport = () => {
  const r = useStore(s => s.toFixedCostReport());

  return (
    <table style={{ tableLayout: 'fixed', width: '50%' }}>
      <thead>
        <tr>
          <th style={{ width: '70%' }}></th>
          <th style={{ textAlign: 'right' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(f => (
          <tr key={f.id}>
            <th>{f.display}</th>
            <td style={{ textAlign: 'right' }}>{f.format(r[f.id])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const fields = [
  { id: 'baseFixedCosts', format: fixedCost, display: 'Base Fixed Costs' },
  {
    id: 'manufacturingEfficiency',
    format: percent,
    display: 'Manufactory Efficiency',
  },
  {
    id: 'extraCostForCapacity',
    format: fixedCost,
    display: 'Extra Cost for Capacity',
  },
  {
    id: 'extraCostForDesign',
    format: fixedCost,
    display: 'Extra Cost for Design',
  },
  {
    id: 'extraCostForEngineering',
    format: fixedCost,
    display: 'Extra Cost for Engineering',
  },
  {
    id: 'extraCostForPerformance',
    format: fixedCost,
    display: 'Extra Cost for Performance',
  },
  {
    id: 'extraCostForRange',
    format: fixedCost,
    display: 'Extra Cost for Range',
  },
  {
    id: 'extraCostForSafety',
    format: fixedCost,
    display: 'Extra Cost for Safety',
  },
  {
    id: 'costForRunningTasks',
    format: fixedCost,
    display: 'R&D Costs (Tasks)',
  },
  {
    id: 'extraCostForEfficiencyImprovement',
    format: fixedCost,
    display: 'Efficiency Improvements',
  },
  {
    id: 'totalFixedCosts',
    format: fixedCost,
    display: 'Total Fixed Costs',
  },
];
